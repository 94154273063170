import { Grid } from "@material-ui/core";
import React from "react";
import MpFormControl from "../../components/MpFormControl/MpFormControl";
import { MpControl, MpControlProps } from "../../types/Form";
import { get } from "react-hook-form";

const defaultSpan = { xs: 12 };

export default function Group({
  control,
  size,
  layout,
  variant,
  register,
  locale,
  errors,
  hookFormControl,
  defaultMessages,
  overridables,
}: MpControlProps) {
  return (
    <Grid container={true} spacing={2}>
      {control.children?.map((control: MpControl, i: number) => (
        <Grid
          item={true}
          key={i}
          xs={12}
          {...((control.span ?? defaultSpan) as any)}
        >
          <MpFormControl
            register={register}
            control={control}
            defaultMessages={defaultMessages}
            error={get(errors ?? {}, control?.key ?? "")}
            size={size}
            locale={locale}
            hookFormControl={hookFormControl}
            noLabel={true}
            layout={layout}
            variant={variant}
            overridables={overridables}
          />
        </Grid>
      ))}
    </Grid>
  );
}
