import {
  MenuItem,
  Box,
  Grow,
  Button,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
} from "@material-ui/core";
import { Close, Menu } from "@material-ui/icons";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MouseEvent,
} from "react";
import { MpTableBulkActionProps } from "../../types/Overridables";
import useStyles from "./MpTableBulkActions.styles";
import clsx from "clsx";
import { MpBulkActionClickParams } from "../../types/Actions";
import { handleBulkAction } from "../../utils/Actions/Actions";

export default function MpTableBulkActions({
  selectedRowIds,
  selectedRows,
  actions,
  methods,
  translations,
}: MpTableBulkActionProps) {
  const classes = useStyles();
  const hasSelectedRows = useMemo(() => selectedRows.length > 0, [
    selectedRows,
  ]);
  const maxWidth = useMemo(() => (hasSelectedRows ? "100%" : 0), [
    hasSelectedRows,
  ]);
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!hasSelectedRows) {
      setOpen(false);
    }
  }, [hasSelectedRows]);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleClick = useCallback(
    (slug: string) => {
      const bulkActionParams: MpBulkActionClickParams = {
        selectedRowIds,
        selectedRows,
        slug,
        methods,
      };
      handleBulkAction(bulkActionParams);
    },
    [selectedRowIds, selectedRows, methods]
  );

  const actionsMap = actions.map((action, i) => (
    <MenuItem
      classes={{ root: classes.menuItem }}
      onClick={(e) => {
        handleClose(e);
        handleClick(action.slug);
      }}
      key={action.slug}
    >
      <Box
        className={clsx(classes.itemDivider, {
          [classes.noBorder]: actions.length === i + 1,
        })}
      >
        {action.label}
      </Box>
    </MenuItem>
  ));

  return (
    <Box maxWidth={maxWidth} overflow="hidden" className={classes.wrapper}>
      <Box width="fit-content">
        <Button
          ref={anchorRef}
          aria-controls={open ? "bulk-actions-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="primary"
          variant="contained"
        >
          {open ? (
            <Close className={classes.iconSpacer} />
          ) : (
            <Menu className={classes.iconSpacer} />
          )}
          {translations?.total ?? "Actions"}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className={classes.overlay}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "left top" : "left bottom",
              }}
            >
              <Paper classes={{ root: classes.menuPaper }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="bulk-actions-list-grow"
                    onKeyDown={handleListKeyDown}
                    disablePadding
                  >
                    {actionsMap}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  );
}
