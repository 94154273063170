import { Box, Checkbox, TableCell } from "@material-ui/core";
import React, { useCallback } from "react";
import { Row, UseRowSelectRowProps } from "react-table";
import { MpTableClasses } from "../../types/Table";
import useStyles from "./MpTableSelectCell.styles";
import clsx from "clsx";
interface Props {
  row: Row & UseRowSelectRowProps<{}>;
  classes?: MpTableClasses;
  selection: "single" | "multiple";
  toggleAllRowsSelected: (value?: boolean) => void;
}

export default function MpTableSelectCell({
  row,
  selection,
  toggleAllRowsSelected,
  classes: overrideClasses,
}: Props) {
  const props = row.getToggleRowSelectedProps();
  const classes = useStyles();

  const handleCheck = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (selection === "single") {
        toggleAllRowsSelected(false);
      }

      row.toggleRowSelected(e.target.checked);
    },
    [row, selection, toggleAllRowsSelected]
  );

  const handlePropagation = useCallback(
    (e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) =>
      e.stopPropagation(),
    []
  );

  return (
    <TableCell
      className={clsx(classes.selectCell, overrideClasses?.selectCell)}
      onClick={handlePropagation}
    >
      <Box display="flex">
        <Checkbox
          color="primary"
          {...props}
          className={clsx(
            classes.cellCheckbox,
            overrideClasses?.selectCellCheckbox
          )}
          onChange={handleCheck}
        />
      </Box>
    </TableCell>
  );
}
