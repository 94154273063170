import {
  Box,
  Icon,
  Switch as MUISwitch,
  SwitchProps,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { CellProps } from "react-table";
import { MpRowActionMethods } from "../../../types/Actions";
import { RtMpColumnInstance } from "../../../types/Table";
import {
  getColumnAction,
  handleRowAction,
} from "../../../utils/Actions/Actions";
import { MetaContext } from "../../../utils/MetaContext/MetaContext";

export function Switch(props: CellProps<{}>) {
  const { column, row, value } = props;
  const { rowMethods, size } = useContext(MetaContext);
  const action = useMemo(() => getColumnAction(column as RtMpColumnInstance), [
    column,
  ]);
  const { iconLeft, iconRight, labelLeft, labelRight, color } = action;
  const innerLeft = iconLeft ? <Icon>{iconLeft}</Icon> : labelLeft;
  const innerRight = iconRight ? <Icon>{iconRight}</Icon> : labelRight;

  const [checked, setChecked] = useState<boolean>(value);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      const value = event.target.checked;
      setChecked(value);
      handleRowAction({
        methods: rowMethods as MpRowActionMethods,
        slug: action.slug,
        row: row,
        rowId: row.id,
        value: value,
      });
    },
    [rowMethods, row, action]
  );

  const handlePropagation = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation(),
    []
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={handlePropagation}
    >
      <Typography component="span">{innerLeft}</Typography>
      <MUISwitch
        size={size}
        onChange={handleChange}
        color={color as SwitchProps["color"]}
        checked={checked}
      />
      <Typography component="span">{innerRight}</Typography>
    </Box>
  );
}
