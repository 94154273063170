import React from "react";
import { FormControlLabel } from "@material-ui/core";
import MuiSwitch from "@material-ui/core/Switch";
import { MpControlProps } from "../../types/Form";

export default function Switch({ control, register, layout }: MpControlProps) {
  return (
    <FormControlLabel
      control={
        <MuiSwitch name={control.key} color="primary" inputRef={register} />
      }
      label={layout === "separated" ? "" : control.label}
    />
  );
}
