import { InputBase } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CellProps } from "react-table";
import { useDebounce } from "../../utils/Debounce/Debounce";
import { MetaContext } from "../../utils/MetaContext/MetaContext";
import { useRendererOptions } from "../../utils/Renderer/Renderer";
import useStyles from "./Input.styles";

export default function Input(props: CellProps<{}>) {
  const classes = useStyles();
  const debounce = useDebounce();
  const { value, inputProps, columnId, columnIndex } = useRendererOptions(
    props
  );
  const { onInputChange } = useContext(MetaContext);

  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => setInputValue(value), [value]);

  const handlePropagation = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation(),
    []
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
      debounce(() => onInputChange?.(e, columnIndex, columnId));
    },
    [columnId, columnIndex, debounce, onInputChange]
  );

  return (
    <InputBase
      onChange={handleInputChange}
      value={inputValue}
      classes={{ root: classes.root, input: classes.input }}
      onClick={handlePropagation}
      {...inputProps}
    />
  );
}
