import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  selectCell: {
    maxWidth: 42,
    paddingRight: 0,
    "&:not(:last-child)": {
      borderRight: "none",
    },
    "tr:last-child &": {
      overflow: "hidden",
    },
  },
  cellCheckbox: {
    margin: -12,
  },
});
