import moment from "moment";
import "moment/min/locales";
import { MpColumnFormatter } from "../../types/Table";

export const formatDate: MpColumnFormatter = ({
  value,
  locale = "en",
  options,
}) => {
  moment.locale(locale);

  const d = new Date(value);
  const m = moment(d);

  return m.format("L" || options?.modifier);
};

export const formatDatetime: MpColumnFormatter = ({
  value,
  locale = "en",
  options,
}) => {
  moment.locale(locale);

  const d = new Date(value);
  const m = moment(d);

  return `${formatDate({ value, locale, options })} ${m.format(
    "LT" || options?.modifier
  )}`;
};
