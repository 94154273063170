import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  endItem: {
    paddingTop: 14,
    paddingBottom: 12,
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 130,
  },
  paper: {
    maxWidth: 340,
    maxHeight: 580,
    overflowY: "auto",
    position: "relative",
    paddingTop: 50,
    "-ms-overflow-style": "none", // IE and Edge
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  toggle: {
    height: 44,
    maxWidth: 195,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: 14,
    paddingRight: 38,
    position: "relative",
    color: "#69717D",
  },
  selected: {
    color: "black",
  },
  arrow: {
    position: "absolute",
    right: 14,
    top: "50%",
    transform: "translateY(-50%)",
  },
  noPadding: {
    padding: 0,
    alignItems: "center",
  },
  typography: {
    padding: "0 35px",
    width: "100%",
  },
  fullWidth: {
    width: "100%",
    position: "relative",
    paddingLeft: 40,
  },
  listItem: {
    background: "white",
    flexDirection: "row-reverse",
    "&:hover": {
      background: "white",
      "& > .MuiTypography-root": {
        fontWeight: "bold",
      },
    },
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderTop: 0,
    borderLeft: 0,
    "&:last-child": {
      borderBottom: 0,
    },
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 20,
    top: "50%",
    transform: "translateY(-50%)",
    "& .MuiSvgIcon-root": {
      width: 12,
      height: 9,
    },
  },
  active: {
    fontWeight: "bold",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 50,
    paddingLeft: 40,
    paddingRight: 25,
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1,
    background: "white",
  },
  headerAction: {
    color: "#69717D",
    cursor: "pointer",
    "&:hover": {
      color: "black",
      fontWeight: "bold",
    },
  },
});
