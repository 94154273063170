import { Box } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { filterMap } from "../../filters/Filters";
import { MpTableOverridablesMap } from "../../types/Overridables";
import { RtMpColumnInstance } from "../../types/Table";

interface Props {
  column: RtMpColumnInstance;
  value?: any;
  setValue: Dispatch<SetStateAction<any | undefined>>;
  overridables: MpTableOverridablesMap;
  doFiltering(immediateValue?: any): void;
}

export default function MpTableFilter({
  column,
  value,
  setValue,
  overridables,
  doFiltering,
}: Props) {
  const filterType = useMemo(
    () =>
      typeof column?.mpColumn?.filter === "string"
        ? column?.mpColumn?.filter
        : column?.mpColumn?.filter?.type,
    [column]
  );
  const FilterComponent = useMemo(
    () =>
      filterType
        ? overridables?.filters?.[filterType as string] ??
          filterMap[filterType as string]
        : null,
    [filterType, overridables]
  );
  return FilterComponent ? (
    <Box>
      <FilterComponent
        column={column}
        value={value}
        setValue={setValue}
        doFiltering={doFiltering}
      />
    </Box>
  ) : null;
}
