import { Box, Grid, InputLabel } from "@material-ui/core";
import React from "react";
import {
  Control,
  FieldError,
  FieldErrors,
  RegisterOptions,
} from "react-hook-form";
import {
  DefaultMessages,
  MpControl,
  MpFormOverridablesMap,
} from "../../types/Form";
import { useControlType } from "../../controls/Controls";

interface Props {
  control: MpControl;
  size?: "small" | "medium";
  layout?: "combined" | "separated";
  noLabel?: boolean;
  variant?: "outlined" | "standard" | "filled";
  register(options?: RegisterOptions): any;
  error?: FieldError;
  errors?: FieldErrors;
  locale?: string;
  hookFormControl: Control;
  overridables?: Partial<MpFormOverridablesMap>;
  defaultMessages: DefaultMessages;
}

export default function MpFormControl({
  register,
  control,
  size,
  layout,
  noLabel,
  variant,
  locale,
  error,
  errors,
  hookFormControl,
  defaultMessages,
  overridables,
}: Props) {
  const Component = useControlType(control, overridables);

  const renderedComponent = (
    <Component
      control={control}
      defaultMessages={defaultMessages}
      register={register}
      locale={locale}
      size={size}
      overridables={overridables}
      hookFormControl={hookFormControl}
      layout={layout}
      variant={variant}
      errors={errors}
      error={error}
    />
  );

  return layout === "separated" && !noLabel ? (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} sm={4}>
        <Box paddingTop={size === "small" ? 1 : 2}>
          <InputLabel error={!!error} required={control.required}>
            {control.label}
          </InputLabel>
        </Box>
      </Grid>
      <Grid item={true} xs={12} sm={8}>
        {renderedComponent}
      </Grid>
    </Grid>
  ) : (
    renderedComponent
  );
}
