import React, { useMemo } from "react";
import useStyles from "./MpDatepicker.styles";
import DatepickerArrowDown from "../../icons/DatepickerArrowDown";
import DatepickerArrowUp from "../../icons/DatepickerArrowUp";

interface Props {
  anchorEl: null | HTMLElement;
}

export default function MpDatepickerArrow({ anchorEl }: Props) {
  const classes = useStyles();

  const Arrow = useMemo(
    () => (anchorEl === null ? DatepickerArrowDown : DatepickerArrowUp),
    [anchorEl]
  );

  return <Arrow className={classes.arrow} />;
}
