import { useMemo } from "react";
import { defaultOverridables } from "../../overridables/Overridables";
import { MpTableOverridablesMap } from "../../types/Overridables";

export function useOverridables(
  overridables?: Partial<MpTableOverridablesMap>
) {
  return useMemo(
    () => ({
      ...defaultOverridables,
      ...(overridables ?? {}),
    }),
    [overridables]
  );
}
