import { useMemo, useCallback, useState, useEffect } from "react";

export const getFromStorage = (
  key: string,
  storage: Storage = localStorage
) => {
  try {
    const raw = storage.getItem(key);
    return raw != null && raw !== "" ? JSON.parse(raw ?? "") : null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
    return null;
  }
};

export const useStorage = <T>(
  key: string,
  storage: Storage = localStorage
): [T | undefined, (value: T) => void] => {
  const cache: T | undefined = useMemo(() => {
    const item = getFromStorage(key, storage);
    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }, [key, storage]);

  const setCache = useCallback(
    (value: T) => {
      storage.setItem(key, JSON.stringify(value));
    },
    [key, storage]
  );

  return [cache, setCache];
};

export const useStoredState = <T = any>(key: string, defaultValue: T) => {
  const [cache, setCache] = useStorage<T>(key);

  const tuple = useState<T>(cache ?? defaultValue);
  const [value] = tuple;

  useEffect(() => {
    setCache(value);
  }, [setCache, value]);

  return tuple;
};
