import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
} from "@material-ui/core";
import { TabUnselected } from "@material-ui/icons";
import React, { MouseEvent, useCallback, useContext, useEffect } from "react";
import { MpFilterProps } from "../../types/Filter";
import { useFilterItems, useFilterOptions } from "../../utils/Filter/Filter";
import { MetaContext } from "../../utils/MetaContext/MetaContext";
import useStyles from "./Select.styles";

const empty: any[] = [];

export default function Select({
  column,
  value = empty,
  setValue,
  doFiltering,
}: MpFilterProps) {
  const { mpColumn } = column;
  const { translations } = useContext(MetaContext);
  const classes = useStyles();
  const filter = useFilterOptions(mpColumn);
  const { loading, items, getItems } = useFilterItems(column, filter);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        if (!value?.includes(event.target.name)) {
          setValue([...(value ?? []), event.target.name]);
        }
      } else {
        setValue(value?.filter((val: any) => val !== event.target.name));
      }
    },
    [setValue, value]
  );

  const handleClear = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      doFiltering(null);
    },
    [doFiltering]
  );

  useEffect(() => {
    getItems();
  }, [getItems]);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        paddingLeft={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle2">{filter?.label ?? ""}</Typography>
        <Box paddingX={2} paddingY={1.5}>
          <Link
            color="textSecondary"
            onClick={handleClear}
            className={classes.emptyAnchor}
          >
            {translations?.clear ?? "Clear"}
          </Link>
        </Box>
      </Box>
      <Divider />
      {!loading && (items?.length ?? 0) > 0 && (
        <Box paddingX={2} paddingY={1} maxHeight={300} overflow="auto">
          <FormControl component="fieldset" color="primary">
            <FormGroup>
              {items?.map((item, i) => (
                <FormControlLabel
                  key={i}
                  onChange={handleChange}
                  name={item.value + ""}
                  control={
                    <Checkbox
                      color="primary"
                      checked={value?.includes(item.value + "") ?? false}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      )}
      {loading && (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && (items?.length ?? 0) < 1 && (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <TabUnselected fontSize="large" color="disabled" />
        </Box>
      )}
    </Box>
  );
}
