import { makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
  footer: {
    transition: theme.transitions.create(["background", "box-shadow"]),
    background: "rgba(0,0,0,0)",
    boxShadow: "none",
  },
  raised: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[15],
  },
}));
