import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export default function BreadcrumbFilterDown(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 8 6" width="8" height="6" {...props}>
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.71074 5.28189C4.3194 5.67729 3.6806 5.67729 3.28926 5.28189L0.489829 2.45345C-0.135134 1.82201 0.31215 0.75 1.20057 0.75L6.79943 0.75C7.68785 0.75 8.13513 1.82201 7.51017 2.45345L4.71074 5.28189Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
}
