import React from "react";
import useStyles from "./MpDatepicker.styles";
import clsx from "clsx";
import { DatepickerListItem } from "../../types/Datepicker";
import Check from "../../icons/Check";
import { List, ListItem, Typography } from "@material-ui/core";

interface IProps {
  data: DatepickerListItem[];
  onClick: (value: string, key: string) => void;
  date: string | null;
}

export default function MpDatepickerItem({ data, onClick, date }: IProps) {
  const classes = useStyles();

  return (
    <List disablePadding>
      {data.map((item) => {
        const isActive = date === item.value;
        return (
          <ListItem
            classes={{
              root: clsx({
                [classes.listItem]: true,
                [classes.noPadding]: !!item?.items,
                [classes.endItem]: !item?.items,
              }),
            }}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              e.stopPropagation();
              onClick(item.value, item.key);
            }}
            button
            key={item.value}
            disableRipple
          >
            <Typography
              className={clsx({
                [classes.typography]: item?.items,
                [classes.fullWidth]: true,
                [classes.active]: isActive,
              })}
              variant="body2"
            >
              {isActive && <Check className={classes.iconContainer} />}
              {item.label}
            </Typography>
            {item?.items && (
              <MpDatepickerItem
                data={item.items}
                onClick={onClick}
                date={date}
              />
            )}
          </ListItem>
        );
      })}
    </List>
  );
}
