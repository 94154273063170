import { TextField } from "@material-ui/core";
import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { NumberInput } from "../../components/NumberInput/NumberInput";
import { MpControlProps } from "../../types/Form";
import {
  booleanDecimalPointToNumber,
  controlRegisterOptions,
  useErrorMessages,
} from "../../utils/Control/Control";

const inputLabelProps = { shrink: true };

export function NumberView({
  control,
  size,
  layout,
  variant,
  error,
  defaultMessages,
  value,
  onChange,
}: MpControlProps & {
  value: any;
  onChange: (...event: any[]) => void;
}) {
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const inputProps = useMemo(
    () => ({
      decimalPoint: booleanDecimalPointToNumber(control.decimalPoint),
      negative: control.negative,
    }),
    [control]
  );
  const { endAdornment, startAdornment } = control;
  const InputProps = useMemo(
    () => ({ inputComponent: NumberInput, endAdornment, startAdornment }),
    [endAdornment, startAdornment]
  );
  return (
    <TextField
      error={!!error}
      helperText={firstMessage}
      name={control.key}
      required={control.required}
      placeholder={control.placeholder}
      label={layout === "separated" ? "" : control.label}
      size={size}
      fullWidth={true}
      InputLabelProps={inputLabelProps}
      value={value ?? ""}
      onChange={onChange}
      inputProps={inputProps}
      InputProps={InputProps}
      variant={variant}
    />
  );
}

export default function Number(props: MpControlProps) {
  const { control, hookFormControl } = props;
  const rules = useMemo(() => controlRegisterOptions(control), [control]);
  return (
    <Controller
      name={control.key ?? ""}
      rules={rules}
      defaultValue={null}
      control={hookFormControl}
      render={({ onChange, value }) => (
        <NumberView {...props} onChange={onChange} value={value} />
      )}
    />
  );
}
