import {
  MpGlobalFilter,
  MpGlobalFilters,
  MpUseGlobalFilters,
} from "./../../types/Filter";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Row } from "react-table";
import { MpFilter, MpFilterItem } from "../../types/Filter";
import {
  MpAsyncGetMethod,
  MpColumn,
  RtMpColumnInstance,
} from "../../types/Table";
import { useDebounce, useUnmounted } from "../Debounce/Debounce";
import { MetaContext } from "../MetaContext/MetaContext";

export function useFilterOptions({ filter }: MpColumn): MpFilter | undefined {
  return useMemo(
    () => (typeof filter === "string" ? { type: filter } : filter),
    [filter]
  );
}

const getAvailableValues = (rows: Row<{}>[], key: string): string[] =>
  rows
    .map((row) => row.values[key])
    .filter((val, i, arr) => arr.indexOf(val) === i);

const executeMethod = (
  method: MpAsyncGetMethod,
  search: string,
  resultSetter: Dispatch<SetStateAction<MpFilterItem[]>>,
  loadingSetter: Dispatch<SetStateAction<boolean>>,
  latestRequest: MutableRefObject<number | null>,
  unmounted: MutableRefObject<boolean>,
  filterItems?: MpFilterItem[]
) => {
  const request = Date.now();
  latestRequest.current = request;
  loadingSetter(true);
  method({ search }).then((result: any) => {
    if (latestRequest.current === request && !unmounted.current) {
      loadingSetter(false);
      if (Array.isArray(result)) {
        resultSetter([...(filterItems ?? []), ...(result ?? [])]);
      }
    }
  });
};

export function useFilterItems(
  column: RtMpColumnInstance,
  filter?: MpFilter
): {
  loading?: boolean;
  items?: MpFilterItem[];
  getItems(search?: string): void;
} {
  const { asyncGetMethods, localFiltering } = useContext(MetaContext);
  const { preFilteredRows, id } = column;
  const unmounted = useUnmounted();
  const [items, setItems] = useState<MpFilterItem[]>();
  const [loading, setLoading] = useState<boolean>(
    !localFiltering &&
      filter?.asyncGet != null &&
      typeof asyncGetMethods?.[filter.asyncGet] === "function"
  );
  const latestRequest = useRef<number | null>(null);
  const debounce = useDebounce(500);

  const getItems = useCallback(
    (searchText?: string) => {
      if (localFiltering && (filter?.items?.length ?? 0) < 1) {
        const localItems = getAvailableValues(preFilteredRows, id)
          .filter((item) =>
            searchText != null && searchText !== ""
              ? (item + "").toLowerCase().indexOf(searchText.toLowerCase()) !==
                -1
              : true
          )
          .map(
            (text: string): MpFilterItem => ({
              value: text,
              label: text,
            })
          );
        setItems(localItems);
      } else if (localFiltering && (filter?.items?.length ?? 0) > 0) {
        setItems(filter?.items);
      } else if (
        filter?.asyncGet != null &&
        typeof asyncGetMethods?.[filter.asyncGet] === "function"
      ) {
        debounce(
          executeMethod,
          asyncGetMethods?.[filter.asyncGet],
          searchText,
          setItems,
          setLoading,
          latestRequest,
          unmounted,
          filter.items
        );
      }
    },
    [
      asyncGetMethods,
      filter?.asyncGet,
      filter?.items,
      id,
      setLoading,
      localFiltering,
      preFilteredRows,
      debounce,
      unmounted,
      latestRequest,
    ]
  );

  return useMemo(
    () => ({
      items,
      getItems,
      loading,
    }),
    [items, getItems, loading]
  );
}

export const useGlobalFilters = (
  initialValue: MpGlobalFilters | undefined
): MpUseGlobalFilters => {
  const [globalFilters, setGlobalFilters] = useState<
    MpGlobalFilters | undefined
  >(initialValue);

  const handleSetGlobalFilter = useCallback((filter: MpGlobalFilter) => {
    setGlobalFilters((prevState) => {
      if (!!prevState)
        return {
          ...prevState,
          [filter.key]: filter.value,
        };

      return { [filter.key]: filter.value };
    });
  }, []);

  return useMemo(
    () => [
      globalFilters as MpGlobalFilters,
      { setGlobalFilters: handleSetGlobalFilter },
    ],
    [globalFilters, handleSetGlobalFilter]
  );
};

export const useRemoteFilters = (mpColumns: MpColumn[]): MpColumn[] => {
  return useMemo(() => {
    return mpColumns.filter((column) => {
      return typeof column.filter !== "string" && column.filter?.remote;
    });
  }, [mpColumns]);
};

export function useClearFilters() {
  const [emptyInstance, setEmptyInstance] = useState<string[] | undefined>(
    undefined
  );
  const clearFilters = useCallback(
    (filters?: string[]) => setEmptyInstance(filters ?? []),
    []
  );

  return { clearFiltersEvent: emptyInstance, clearFilters };
}
