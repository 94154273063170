import { Box, Button, Divider } from "@material-ui/core";
import { SortByAlpha } from "@material-ui/icons";
import React, { useCallback } from "react";
import MpTableFilter from "../../components/MpTableFilter/MpTableFilter";
import { MpTableHeadFilterProps } from "../../types/Overridables";

export default function MpTableHeadFilter({
  column,
  canSort,
  isSortedDesc,
  doSorting,
  doFiltering,
  value,
  setValue,
  overridables,
}: MpTableHeadFilterProps) {
  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      doFiltering();
    },
    [doFiltering]
  );
  return (
    <form onSubmit={handleSubmit}>
      <Box minWidth={150}>
        <MpTableFilter
          column={column}
          value={value}
          setValue={setValue}
          doFiltering={doFiltering}
          overridables={overridables}
        />
        <Divider />
        <Box display="flex" justifyContent="center" alignItems="center">
          {canSort && (
            <Box
              paddingX={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ cursor: "pointer" }}
              onClick={doSorting}
            >
              <SortByAlpha
                fontSize="small"
                color={isSortedDesc == null ? "action" : "primary"}
              />
            </Box>
          )}
          <Box flexGrow={1} />
          <Button color="primary" type="submit">
            Filter
          </Button>
        </Box>
      </Box>
    </form>
  );
}
