import { useMemo } from "react";
import { Row } from "react-table";
import { MpTableHighlights } from "../../types/Table";

export function useRowHighlights(row: Row, highlights?: MpTableHighlights) {
  const { original } = row;
  return useMemo(
    () => ({
      inactive: highlights?.inactive
        ? !!original?.[highlights?.inactive]
        : false,
      primary: highlights?.primary ? !!original?.[highlights?.primary] : false,
      secondary: highlights?.secondary
        ? !!original?.[highlights?.secondary]
        : false,
    }),
    [original, highlights]
  );
}
