import { useMemo } from "react";
import { FieldError, RegisterOptions } from "react-hook-form";
import { DefaultMessages, MpControl } from "../../types/Form";

export function controlRegisterOptions(control: MpControl): RegisterOptions {
  let pattern: RegExp | undefined;
  if (control.validations?.pattern != null) {
    try {
      pattern = new RegExp(control.validations?.pattern);
    } catch (e) {
      // eslint-disable-next-line
      console.warn(
        "Provided pattern is not a valid RegExp",
        control.validations.pattern
      );
    }
  }
  const { min, max, minLength, maxLength } = control.validations ?? {};
  return {
    required: control.required,
    min,
    max,
    minLength,
    maxLength,
    pattern: pattern,
  };
}

export const defaultMessages: DefaultMessages = {
  required: (control: MpControl) =>
    `${control.label ?? control.key} is required`,
  min: (control: MpControl) => `${control.label ?? control.key} is too small`,
  max: (control: MpControl) => `${control.label ?? control.key} is too large`,
  minLength: (control: MpControl) =>
    `${control.label ?? control.key} is too short`,
  maxLength: (control: MpControl) =>
    `${control.label ?? control.key} is too long`,
  pattern: (control: MpControl) =>
    `${control.label ?? control.key} is not valid`,
};

export function useErrorMessages(
  control: MpControl,
  defaultMessages: DefaultMessages,
  error?: FieldError
): string[] {
  return useMemo(() => {
    const errorKeys = [
      error?.type,
      ...Object.keys(error?.types ?? {}).filter(
        (type) => error?.types?.[type] != null
      ),
    ].filter((key) => key != null);
    return errorKeys.map(
      (key) =>
        control?.messages?.[key ?? ""] ??
        defaultMessages[key || "pattern"](control)
    );
  }, [control, error, defaultMessages]);
}

export function booleanDecimalPointToNumber(
  decimalPoint?: boolean | number
): number | undefined {
  switch (decimalPoint) {
    case true:
      return undefined;
    case false:
      return 0;
    default:
      return decimalPoint;
  }
}
