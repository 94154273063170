import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  naked: {
    width: "100%",
  },
  emptyAnchor: {
    cursor: "pointer",
    userSelect: "none",
  },
}));
