import { Dispatch, SetStateAction } from "react";
import { Row } from "react-table";
import { RtMpColumnInstance } from "./Table";

export enum MpFilterType {
  text = "text",
  select = "select",
  searchSelect = "searchSelect",
  numberRange = "numberRange",
}

export type FilterFunction = (
  rows: Row[],
  columnIds: string[],
  filterValue: any
) => Row[];

export interface MpFilterItem {
  // Value of the filter item
  value: string | number | boolean;
  // User-readable label for the item
  label: string;
}

export interface MpFilter {
  // Get/Set filter value using the key
  key?: string;
  // Type of the filter
  type: MpFilterType;
  // (Optional) Display title of the filter
  label?: string;
  // Placeholder
  placeholder?: string;
  // (Optional) Display unit in an input adornment
  unitLabel?: string;
  // (Optional) Override the items available in the filter
  items?: MpFilterItem[];
  // (Optional) Async Get Method key
  asyncGet?: string;
  // Format (i.e date, number, etc.)
  format?: string;
  // Locale override
  locale?: string;
  // Separate from localFiltering
  remote?: boolean;
}

export interface MpFilterProps {
  column: RtMpColumnInstance;
  value?: any;
  setValue: Dispatch<SetStateAction<any | undefined>>;
  doFiltering(immediateValue?: any): void;
}

export interface MpGlobalFilter {
  key: string;
  value: any;
}

export interface MpGlobalFilters {
  [key: string]: any;
}

export type MpUseGlobalFilters = [
  MpGlobalFilters,
  { setGlobalFilters: (filter: MpGlobalFilter) => void }
];

export interface MpBreadcrumbFilterData {
  id?: string;
  name?: string;
  [key: string]: MpBreadcrumbFilterData[] | string | undefined;
}
