import { MpColumnFormatter } from "../../types/Table";

export const toCurrency: MpColumnFormatter = ({ value, locale, options }) => {
  return (
    value?.toLocaleString(
      locale,
      !!options?.modifier
        ? { style: "currency", currency: options?.modifier }
        : undefined
    ) ?? null
  );
};
