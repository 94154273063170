import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export default function Check(props: SvgIconProps) {
  return (
    <SvgIcon
      fontSize="small"
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      {...props}
      style={{ fill: "none", width: 12, height: 9, overflow: "visible" }}
    >
      <path d="M1 4L4.5 7.5L11 1" stroke="#4DA621" strokeWidth="2" />
    </SvgIcon>
  );
}
