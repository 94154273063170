import { TableCell, TableRow } from "@material-ui/core";
import React, { useCallback } from "react";
import { Row, UseRowSelectRowProps } from "react-table";
import MpTableSelectCell from "../MpTableSelectCell/MpTableSelectCell";
import clsx from "clsx";
import useStyles from "./MpTableRow.styles";
import {
  MpTableClasses,
  MpTableHighlights,
  RtMpColumnInstance,
} from "../../types/Table";
import { useRowHighlights } from "../../utils/Highlights/Highlights";

interface Props {
  row: Row & UseRowSelectRowProps<{}>;
  selection?: "single" | "multiple";
  classes?: MpTableClasses;
  highlights?: MpTableHighlights;
  toggleAllRowsSelected: (value?: boolean) => void;
  onRowClick?: (row?: Row & UseRowSelectRowProps<{}>) => void;
}

export default function MpTableRow({
  row,
  selection,
  classes: overrideClasses,
  toggleAllRowsSelected,
  highlights,
  onRowClick,
}: Props) {
  const { inactive, primary, secondary } = useRowHighlights(row, highlights);
  const classes = useStyles();

  const handleRowClick = useCallback(() => {
    onRowClick?.(row);
  }, [onRowClick, row]);

  const handleFixedWidth = useCallback((cell) => {
    const cellWidth = (cell.column as RtMpColumnInstance).mpColumn?.width;
    return cellWidth
      ? {
          minWidth: cellWidth,
          width: cellWidth,
          maxWidth: cellWidth,
        }
      : undefined;
  }, []);

  return (
    <TableRow
      {...row.getRowProps()}
      className={clsx({
        [overrideClasses?.row ?? ""]: overrideClasses?.row != null,
        [overrideClasses?.highlightedInactive ?? classes.highlightedInactive]:
          inactive,
        [overrideClasses?.highlightedPrimary ?? classes.highlightedPrimary]:
          primary,
        [overrideClasses?.highlightedSecondary ?? classes.highlightedSecondary]:
          secondary,
        [classes.pointer]: onRowClick,
        [overrideClasses?.rowSelected ?? ""]:
          overrideClasses?.rowSelected != null && row.isSelected,
      })}
      onClick={handleRowClick}
    >
      {selection != null && (
        <MpTableSelectCell
          classes={overrideClasses}
          row={row}
          selection={selection}
          toggleAllRowsSelected={toggleAllRowsSelected}
        />
      )}
      {row.cells.map((cell) => {
        return (
          <TableCell
            {...cell.getCellProps()}
            className={clsx(classes.cell, {
              [classes.renderedCell]:
                (cell.column as any).mpColumn.render != null,
            })}
            style={handleFixedWidth(cell)}
          >
            {cell.render("Cell")}
          </TableCell>
        );
      })}
    </TableRow>
  );
}
