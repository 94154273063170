import { TextField } from "@material-ui/core";
import React, { useMemo } from "react";
import { MpControlProps } from "../../types/Form";
import {
  controlRegisterOptions,
  useErrorMessages,
} from "../../utils/Control/Control";

const inputLabelProps = { shrink: true };

export default function Multiline({
  control,
  size,
  layout,
  variant,
  register,
  error,
  defaultMessages,
}: MpControlProps) {
  const inputProps = useMemo(
    () => ({
      ref: register(controlRegisterOptions(control)),
    }),
    [register, control]
  );
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);

  return (
    <TextField
      error={!!error}
      multiline={true}
      rows={4}
      rowsMax={8}
      helperText={firstMessage}
      name={control.key}
      required={control.required}
      placeholder={control.placeholder}
      label={layout === "separated" ? "" : control.label}
      size={size}
      fullWidth={true}
      InputLabelProps={inputLabelProps}
      inputProps={inputProps}
      variant={variant}
    />
  );
}
