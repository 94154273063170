import { ComponentType } from "react";
import {
  Control,
  FieldError,
  FieldErrors,
  RegisterOptions,
  UseFormMethods,
} from "react-hook-form";

export enum MpControlType {
  number = "number",
  text = "text",
  multiline = "multiline",
  group = "group",
  switch = "switch",
}

export type GridSpan =
  | undefined
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | "auto";

export interface MpControlValidations {
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
}

export type ValidationKeys =
  | "required"
  | "min"
  | "max"
  | "minLength"
  | "maxLength"
  | "pattern";

export type DefaultMessages = Record<
  ValidationKeys,
  (control: MpControl) => string
>;

export interface MpControl {
  // Write field value to this key
  key?: string;
  // Type
  type: MpControlType;
  // Label of the field
  label?: string;
  // Placeholder
  placeholder?: string;
  // End Adornment
  endAdornment?: string;
  // Start Adornment
  startAdornment?: string;
  // Required
  required?: boolean;
  // Validations
  validations?: MpControlValidations;
  // Messages
  messages?: Partial<Record<ValidationKeys, string>>;
  // Grid columns span
  span?: {
    xs?: GridSpan;
    sm?: GridSpan;
    md?: GridSpan;
    lg?: GridSpan;
    xl?: GridSpan;
  };
  // Group children
  children?: MpControl[];
  // Format (i.e date, number, etc.)
  format?: string;
  // Negative values
  negative?: boolean;
  // Decimal point
  decimalPoint?: number | boolean;
  // Locale override
  locale?: string;
}

export interface MpControlProps {
  control: MpControl;
  size?: "small" | "medium";
  layout?: "combined" | "separated";
  variant?: "outlined" | "standard" | "filled";
  register(options?: RegisterOptions): any;
  error?: FieldError;
  hookFormControl: Control;
  errors?: FieldErrors;
  locale?: string;
  defaultMessages: DefaultMessages;
  overridables?: Partial<MpFormOverridablesMap>;
}

export interface MpFormType {
  // Array of MpControls
  controls: MpControl[];
  // Condensed or regular controls
  size?: "small" | "medium";
  // Layout type
  layout?: "combined" | "separated";
  // Control variant
  variant?: "outlined" | "standard" | "filled";
  // For values formatters
  locale?: string;
}

export interface MpFormSubmitArguments {
  // Form values
  values?: any;
}

export interface MpFormProps extends MpFormType, MpFormInputProps {}

export interface MpFormInputProps {
  useFormMethods: UseFormMethods;
  classes?: MpFormClasses;
  translations?: MpFormTranslations;
  overridables?: Partial<MpFormOverridablesMap>;
  defaultMessages?: Partial<DefaultMessages>;
}

export interface MpFormClasses {}

export interface MpFormTranslations {}

export interface MpFormOverridablesMap {
  controls: Record<string, ComponentType<MpControlProps>>;
}
