import { Box, Chip } from "@material-ui/core";
import createPalette from "@material-ui/core/styles/createPalette";
import React, { useContext, useMemo } from "react";
import { CellProps } from "react-table";
import { MetaContext } from "../../utils/MetaContext/MetaContext";
import { useRendererOptions } from "../../utils/Renderer/Renderer";

export default function StatusPill(props: CellProps<{}>) {
  const { style, value } = useRendererOptions(props);
  const { size } = useContext(MetaContext);

  const chipStyle = useMemo(
    () => ({
      backgroundColor: style != null ? style + "" : undefined,
      color:
        style != null
          ? createPalette({ primary: { main: style + "" } }).getContrastText(
              style + ""
            )
          : undefined,
    }),
    [style]
  );
  return (
    <Box textAlign="center">
      <Chip size={size} style={chipStyle} label={value} />
    </Box>
  );
}
