import { TableCell } from "@material-ui/core";
import React, { useCallback, useMemo, useRef, useState } from "react";
import useStyles from "./MpTableHeadCell.styles";
import clsx from "clsx";
import { TableCommonProps } from "react-table";
import MpTableHeadCellOptions from "../MpTableHeadCellOptions/MpTableHeadCellOptions";
import { MpTableClasses, RtMpColumnInstance } from "../../types/Table";
import { MpTableOverridablesMap } from "../../types/Overridables";

interface Props extends TableCommonProps {
  column: RtMpColumnInstance;
  variant?: "simple" | "extended";
  overridables: MpTableOverridablesMap;
  clearFiltersEvent?: string[];
  clearColumnFilters?: boolean;
  classes?: MpTableClasses;
}

export default function MpTableHeadCell({
  column,
  variant = "simple",
  overridables,
  clearFiltersEvent,
  clearColumnFilters,
  classes: overrideClasses,
}: // style,
// className,
// role,
Props) {
  const {
    onClick: doSorting,
    title,
    ...sortProps
  } = column.getSortByToggleProps() as any;
  const { isSortedDesc } = column;
  const { width: fixedColumnWidth } = column?.mpColumn;
  const classes = useStyles({ fixedColumnWidth });
  const cellRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [value, setValue] = useState<any | undefined>(column.filterValue);

  const hasFilter = useMemo(() => !!column.mpColumn?.filter, [column]);
  const hasWidth = useMemo(
    () => !!column.mpColumn?.width && !!column.mpColumn?.fixedHeadWidth,
    [column]
  );
  const isExtended = useMemo(
    () => variant === "extended" && hasFilter,
    [variant, hasFilter]
  );

  const [optionsAnchor, setOptionsAnchor] = useState<Element | null>(null);

  const closeOptions = useCallback(
    () => setOptionsAnchor(null),
    [setOptionsAnchor]
  );

  const handleClick = useCallback(
    (event?: React.MouseEvent<Element>) => {
      if (isExtended) {
        setOptionsAnchor(cellRef.current);
      } else if (column.canSort) {
        doSorting(event);
      }
    },
    [doSorting, isExtended, column]
  );

  const handleClearFilters = useCallback(
    (e: React.MouseEvent<SVGElement>) => {
      e.stopPropagation();
      column.setFilter(null);
      setValue(null);
    },
    [column]
  );

  const handleMouseEnter = useCallback(() => setIsHovered(true), []);

  const handleMouseLeave = useCallback(() => setIsHovered(false), []);

  const filterSet =
    column.filterValue != null &&
    column.filterValue !== "" &&
    (!Array.isArray(column.filterValue) || column.filterValue?.length > 0);

  const highlightCell = useMemo(
    () => filterSet && isHovered && clearColumnFilters,
    [filterSet, isHovered, clearColumnFilters]
  );

  return (
    <>
      <TableCell
        className={clsx({
          [classes.fixedWidth]: hasWidth,
          [overrideClasses?.hoveredCellWithFilters ??
          classes.hoveredCellWithFilters]: highlightCell,
        })}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...sortProps}
      >
        <div
          ref={cellRef}
          className={clsx(classes.wrapper, {
            [classes.disabledSort]: !column.canSort && !isExtended,
          })}
        >
          {column.render("Header")}{" "}
          <overridables.headIndicator
            canSort={column.canSort}
            isExtended={isExtended}
            filterSet={filterSet}
            isSortedDesc={isSortedDesc}
            isHoveredCell={isHovered}
            hasClear={clearColumnFilters}
            clearFilters={handleClearFilters}
          />
        </div>
      </TableCell>
      {isExtended}
      <MpTableHeadCellOptions
        anchorEl={optionsAnchor}
        overridables={overridables}
        onClose={closeOptions}
        canSort={column.canSort}
        isSortedDesc={isSortedDesc}
        doSorting={doSorting}
        column={column}
        clearFiltersEvent={clearFiltersEvent}
        value={value}
        setValue={setValue}
      />
    </>
  );
}
