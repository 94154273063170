import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  PopperProps,
} from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import {
  Datepicker,
  DatepickerListItem,
  FromToFilterProps,
} from "../../types/Datepicker";
import useDatepicker, {
  useDatepickerData,
} from "../../utils/Datepicker/Datepicker";
import useStyles from "./MpDatepicker.styles";
import MpDatepickerItem from "./MpDatepickerItem";
import MpDatepickerHeader from "./MpDatepickerHeader";
import { MpTableTranslations } from "../../types/Table";
import MpDatepickerArrow from "./MpDatepickerArrow";

interface Props {
  items?: DatepickerListItem[];
  placement?: PopperProps["placement"];
  translations?: MpTableTranslations;
  FromToFilter?: React.ComponentType<FromToFilterProps>;
  onGetData: (data: Datepicker) => void;
}

export default function MpDatepicker({
  items,
  placement,
  translations,
  FromToFilter,
  onGetData,
}: Props) {
  const classes = useStyles();
  const {
    setDatepickerValues,
    clearSelected,
    date,
    handleFilterClick,
    dateFrom,
    dateTo,
  } = useDatepicker();
  const defaultData = useDatepickerData();
  const data = useMemo(() => items ?? defaultData, [defaultData, items]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = useMemo(() => !!anchorEl, [anchorEl]);
  const id = useMemo(() => (open ? "datepicker-popper" : undefined), [open]);
  const filterProps = useMemo(
    (): FromToFilterProps => ({
      onFilter: () => {
        handleFilterClick();
        setAnchorEl(null);
      },
      onDateChange: setDatepickerValues,
      dateFrom: dateFrom,
      dateTo: dateTo,
      onGetData: onGetData,
    }),
    [dateFrom, dateTo, handleFilterClick, onGetData, setDatepickerValues]
  );

  const togglePopper = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const handleDatepickerClick = useCallback(
    (value: string, key: string) => {
      setAnchorEl(null);
      setDatepickerValues(value, key, onGetData);
    },
    [onGetData, setDatepickerValues]
  );

  const handleSelectAll = useCallback(() => {
    clearSelected(onGetData);
    setAnchorEl(null);
  }, [clearSelected, onGetData]);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Box>
        <Paper
          className={clsx({
            [classes.toggle]: true,
            [classes.selected]: !!date,
          })}
          aria-describedby={id}
          onClick={togglePopper}
        >
          {date ?? "All time"}
          <MpDatepickerArrow anchorEl={anchorEl} />
        </Paper>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          style={{ zIndex: 1299 }}
          placement={placement ?? "bottom-end"}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={4} className={classes.paper}>
                <MpDatepickerHeader
                  translations={translations}
                  date={date}
                  onSelectAll={handleSelectAll}
                  FromToFilter={FromToFilter}
                  filterProps={filterProps}
                />
                <MpDatepickerItem
                  date={date}
                  data={data}
                  onClick={handleDatepickerClick}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
