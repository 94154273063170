import React, { useCallback, useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import { MpTableProps, RtMpColumnInstance } from "../../types/Table";
import {
  Column,
  Filters,
  Row,
  SortingRule,
  TableInstance,
  TableOptions,
  TableState,
  useFilters,
  UseFiltersInstanceProps,
  UseFiltersOptions,
  UseFiltersState,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useRowSelect,
  UseRowSelectInstanceProps,
  UseRowSelectRowProps,
  UseRowSelectState,
  useSortBy,
  UseSortByInstanceProps,
  UseSortByState,
  useTable,
} from "react-table";
import { Box, Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import { convertMpToColumn } from "../../utils/ColumnAdapter/ColumnAdapter";
import { useDataFormatters } from "../../utils/DataFormatter/DataFormatter";
import { useDataArguments } from "../../utils/DataArguments/DataArguments";
import MpTableHeadCell from "../MpTableHeadCell/MpTableHeadCell";
import useStyles from "./MpTable.styles";
import MpTableEmptyBody from "../MpTableEmptyBody/MpTableEmptyBody";
import MpTableHeadSelectCell from "../MpTableHeadSelectCell/MpTableHeadSelectCell";
import { useOverridables } from "../../utils/Overridables/Overridables";
import MpTableTotals from "../MpTableTotals/MpTableTotals";
import { MetaContext } from "../../utils/MetaContext/MetaContext";
import { Meta } from "../../types/Meta";
import { MpBulkActionMethods, MpActions } from "../../types/Actions";
import MpTableRow from "../MpTableRow/MpTableRow";
import { useStorage } from "../../utils/Storage/Storage";
import { MpGlobalFilters } from "../../types/Filter";
import { useGlobalFilters, useRemoteFilters } from "../../utils/Filter/Filter";

const emptyData: any[] = [];

export default function MpTable<ItemType extends Object = any>({
  columns: mpColumns,
  data = emptyData,
  loading,
  size,
  onGetData,
  dataCount,
  storageIdentifier,
  cacheFilters,
  cacheSorting,
  cacheGlobalFilter,
  filterVariant = "head",
  localPagination = false,
  localSorting = false,
  localFiltering = false,
  translations,
  pageSize = 10,
  overridables: partialOverridables,
  rowIdKey,
  selection,
  totals,
  stickyTotals,
  totalsData,
  bulkActions,
  bulkMethods,
  rowMethods,
  highlights,
  clearColumnFilters = false,
  classes: overrideClasses,
  asyncGetMethods,
  locale,
  disablePagination,
  disableSorting,
  onRowClick,
  enableGlobalActions,
  breadcrumbFilterData,
  onEdit,
  onRowsSelected,
  emptyChildren,
  loadingComponent,
  clearFiltersEvent,
  resetSelectedRows = true,
}: MpTableProps<ItemType>) {
  const columns: Column<any>[] = useMemo(
    () => mpColumns.map(convertMpToColumn(partialOverridables, disableSorting)),
    [mpColumns, partialOverridables, disableSorting]
  );
  const remoteFiltersColumns = useRemoteFilters(mpColumns);
  const classes = useStyles();
  const bodyRef = useRef<HTMLTableSectionElement>(null);

  const pageCount = useMemo(
    () =>
      dataCount && !disablePagination
        ? Math.ceil((dataCount ?? 0) / pageSize)
        : undefined,
    [dataCount, pageSize, disablePagination]
  );
  const [filtersCache, setFiltersCache] = useStorage<Filters<{}>>(
    `${storageIdentifier}-filters`
  );
  const [sortingCache, setSortingCache] = useStorage<SortingRule<{}>[]>(
    `${storageIdentifier}-sorting`
  );
  const [globalFiltersCache, setGlobalFiltersCache] = useStorage<
    MpGlobalFilters | undefined
  >(`${storageIdentifier}-globalFilter`);
  const [globalFilters, { setGlobalFilters }] = useGlobalFilters(
    cacheGlobalFilter ? globalFiltersCache : undefined
  );

  const initialState = useMemo(
    () =>
      ({
        pageIndex: 0,
        filters: cacheFilters ? filtersCache ?? [] : [],
        sortBy: cacheSorting ? sortingCache ?? [] : [],
        pageSize: disablePagination ? undefined : pageSize,
      } as Partial<TableState & UsePaginationState<{}>>),
    [
      filtersCache,
      cacheFilters,
      sortingCache,
      cacheSorting,
      pageSize,
      disablePagination,
    ]
  );

  const overridables = useOverridables(partialOverridables);
  const [dataState, setDataState] = useDataFormatters<ItemType>(
    data,
    mpColumns,
    locale
  );

  const getRowId = useCallback((row: Row) => row?.[rowIdKey], [rowIdKey]);

  const tableOptions = useMemo(
    () =>
      ({
        columns,
        data: dataState,
        initialState,
        manualPagination: !localPagination,
        manualSortBy: !localSorting,
        manualFilters: !localFiltering,
        autoResetFilters: !(localFiltering && !!remoteFiltersColumns?.length),
        getRowId,
        autoResetPage: false,
        pageCount,
        autoResetSelectedRows: resetSelectedRows,
      } as TableOptions<{}> & UsePaginationOptions<{}> & UseFiltersOptions<{}>),
    [
      columns,
      dataState,
      initialState,
      localPagination,
      localSorting,
      localFiltering,
      remoteFiltersColumns?.length,
      getRowId,
      pageCount,
    ]
  );

  const tableInstance = useTable(
    tableOptions,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const {
    visibleColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    gotoPage,
    getToggleAllRowsSelectedProps,
    toggleAllRowsSelected,
    selectedFlatRows,
    setAllFilters,
    setFilter,
  } = tableInstance as TableInstance &
    UsePaginationInstanceProps<{}> &
    UseSortByInstanceProps<{}> &
    UseFiltersInstanceProps<{}> &
    UseRowSelectInstanceProps<{}>;
  const state = tableInstance.state as TableState &
    UsePaginationState<{}> &
    UseSortByState<{}> &
    UseFiltersState<{}> &
    UseRowSelectState<{}>;

  const dataArguments = useDataArguments({
    state,
    visibleColumns,
    localFiltering,
    localPagination,
    localSorting,
    rowIdKey,
    globalFilters,
    remoteFiltersColumns,
  });

  useEffect(() => {
    onGetData(dataArguments);
  }, [onGetData, dataArguments]);

  useEffect(() => {
    if (cacheFilters) {
      setFiltersCache(state.filters);
    }
  }, [cacheFilters, state.filters, setFiltersCache]);

  useEffect(() => {
    if (cacheSorting) {
      setSortingCache(state.sortBy);
    }
  }, [cacheSorting, state.sortBy, setSortingCache]);

  useEffect(() => {
    if (cacheGlobalFilter) {
      setGlobalFiltersCache(globalFilters);
    }
  }, [cacheGlobalFilter, setGlobalFiltersCache, globalFilters]);

  useEffect(() => {
    onEdit?.(dataState);
  }, [dataState, onEdit]);

  useEffect(() => {
    if (typeof onRowsSelected === "function") {
      onRowsSelected(selectedFlatRows);
    }
  }, [selectedFlatRows, onRowsSelected]);

  useEffect(() => {
    if (clearFiltersEvent != null) {
      if (!clearFiltersEvent?.length) setAllFilters([]);
      if (!!clearFiltersEvent?.length) {
        clearFiltersEvent.forEach((filter) => setFilter(filter, []));
      }
    }
  }, [clearFiltersEvent, setAllFilters, setFilter]);

  const bodyStyle = useMemo(
    () => ({
      opacity: loading ? 0.5 : undefined,
    }),
    [loading]
  );

  const cellCount = useMemo(() => {
    return columns.length + (!!selection ? 1 : 0);
  }, [columns, selection]);

  const meta: Meta<ItemType> = useMemo(
    () => ({
      totalsData,
      size,
      rowMethods,
      translations,
      locale,
      asyncGetMethods,
      localPagination,
      localSorting,
      localFiltering,
      onInputChange: setDataState,
    }),
    [
      totalsData,
      size,
      rowMethods,
      translations,
      locale,
      asyncGetMethods,
      localPagination,
      localSorting,
      localFiltering,
      setDataState,
    ]
  );

  return (
    <MetaContext.Provider value={meta}>
      <Box className={classes.parent}>
        {enableGlobalActions && (
          <overridables.globalActions
            selectedRows={selectedFlatRows}
            selectedRowIds={state.selectedRowIds}
            actions={bulkActions as MpActions}
            methods={bulkMethods as MpBulkActionMethods}
            translations={translations}
            onGlobalFilter={setGlobalFilters}
            globalFilters={globalFilters}
            breadcrumbFilterData={breadcrumbFilterData}
          />
        )}
        <Box
          className={clsx(classes.wrapper, overrideClasses?.tableWrapper, {
            [classes.stickyTotalsWrapper]: stickyTotals,
          })}
        >
          <Table
            size={size}
            {...getTableProps()}
            className={clsx(classes.table, overrideClasses?.table)}
          >
            <TableHead>
              {headerGroups.map((headerGroup: any) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {selection != null && (
                    <MpTableHeadSelectCell
                      classes={overrideClasses}
                      selection={selection}
                      {...getToggleAllRowsSelectedProps()}
                    />
                  )}
                  {headerGroup.headers.map((column: RtMpColumnInstance) => (
                    <MpTableHeadCell
                      {...column.getHeaderProps()}
                      overridables={overridables}
                      variant={filterVariant === "head" ? "extended" : "simple"}
                      column={column}
                      clearFiltersEvent={clearFiltersEvent}
                      clearColumnFilters={clearColumnFilters}
                      classes={overrideClasses}
                    />
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()} style={bodyStyle} ref={bodyRef}>
              {page.length < 1 && (
                <MpTableEmptyBody
                  cellCount={cellCount}
                  loading={loading}
                  emptyChildren={emptyChildren}
                  loadingComponent={loadingComponent}
                />
              )}
              {page.map((row: Row & UseRowSelectRowProps<{}>) => {
                prepareRow(row);
                return (
                  <MpTableRow
                    key={row.getRowProps().key}
                    row={row}
                    highlights={highlights}
                    classes={overrideClasses}
                    selection={selection}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                    onRowClick={onRowClick}
                  />
                );
              })}
            </TableBody>
            {!!totals && !!totalsData && (
              <MpTableTotals
                footerGroups={footerGroups}
                hasSelection={selection != null}
                classes={overrideClasses}
                bodyRef={bodyRef}
                stickyTotals={stickyTotals}
              />
            )}
          </Table>
        </Box>
        {!disablePagination && (
          <overridables.paginator
            pageSize={state.pageSize}
            itemCount={dataCount}
            pageCount={pageCount}
            pageIndex={state.pageIndex}
            previousPage={previousPage}
            nextPage={nextPage}
            gotoPage={gotoPage}
            loading={loading}
            translations={translations}
            selectedCount={selectedFlatRows.length}
          />
        )}
      </Box>
    </MetaContext.Provider>
  );
}
