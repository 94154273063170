import { useContext } from "react";
import {
  Column,
  UseFiltersColumnOptions,
  UseSortByColumnOptions,
} from "react-table";
import { filterTypeMap } from "../../filters/Filters";
import { rendererMap } from "../../renderers/Renderers";
import { MpFilter } from "../../types/Filter";
import { MpTableOverridablesMap } from "../../types/Overridables";
import { MpColumn } from "../../types/Table";
import { MetaContext } from "../MetaContext/MetaContext";

export const getFooterValue = (key: string) => () => {
  const { totalsData } = useContext(MetaContext);
  return totalsData?.[key] ?? null;
};

const noOpFilter = (rows: Array<any>) => rows;

export const convertMpToColumn = (
  overridables?: Partial<MpTableOverridablesMap>,
  disableSorting?: boolean
) => <ItemType extends Object = any>(column: MpColumn) => {
  const col: Column<ItemType> &
    UseSortByColumnOptions<{}> &
    UseFiltersColumnOptions<{}> & {
      mpColumn: MpColumn;
      Footer?: any;
    } = {
    Header: column.label ?? column.key,
    accessor: column.key as any,
    mpColumn: column,
    disableSortBy: disableSorting || column.sort === false,
  };
  if (column?.filter != null && !(column?.filter as MpFilter)?.remote) {
    const type =
      typeof column.filter === "string" ? column.filter : column.filter.type;
    col.filter =
      overridables?.filterFunctions?.[type as string] ??
      filterTypeMap[type as string];
  }
  if (column?.filter != null && (column?.filter as MpFilter)?.remote === true) {
    col.filter = noOpFilter;
  }
  if (column.totals !== false) {
    col.Footer = getFooterValue(column.key);
  }
  if (column.render != null) {
    const type =
      typeof column.render === "string" ? column.render : column.render.type;
    col.Cell = overridables?.renderers?.[type] ?? rendererMap[type];
  }
  return col;
};
