import { useCallback, useEffect, useRef } from "react";

export function useDebounce(ms: number = 500) {
  const handle = useRef<number | null>(null);

  const debounce = useCallback(
    (cb: Function, ...args: any[]) => {
      if (handle.current) {
        clearTimeout(handle.current);
      }
      handle.current = setTimeout(cb, ms, ...args);
    },
    [ms]
  );

  return debounce;
}

export function useUnmounted() {
  const ref = useRef<boolean>(false);
  useEffect(
    () => () => {
      ref.current = true;
    },
    []
  );
  return ref;
}
