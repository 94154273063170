import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  wrapper: {
    display: "flex",
    width: "100%",
    position: "relative",
    minHeight: 48,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  spacer: {
    flexGrow: 1,
  },
});
