import React, { useCallback, useContext, useMemo, useState } from "react";
import { CellProps } from "react-table";
import { RtMpColumnInstance } from "../../../types/Table";
import {
  getColumnAction,
  handleRowAction,
} from "../../../utils/Actions/Actions";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Icon } from "@material-ui/core";
import { MpRowActionItem, MpRowActionMethods } from "../../../types/Actions";
import { MetaContext } from "../../../utils/MetaContext/MetaContext";

export function Toggle(props: CellProps<{}>) {
  const { column, row, value } = props;
  const { rowMethods, size } = useContext(MetaContext);
  const action = useMemo(() => getColumnAction(column as RtMpColumnInstance), [
    column,
  ]);

  const { items, defaultValue } = action;
  const [toggle, setToggle] = useState<string | number | null | undefined>(
    value ?? defaultValue
  );

  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newToggle: string | null) => {
      event.stopPropagation();
      handleRowAction({
        methods: rowMethods as MpRowActionMethods,
        slug: action.slug,
        value: newToggle as string,
        row: row,
        rowId: row.id,
      });
      setToggle(newToggle);
    },
    [setToggle, action, row, rowMethods]
  );

  const mapToggles = useMemo(
    () =>
      (items ? items : []).map((item: MpRowActionItem) => {
        const { value, icon, label, color, textColor } = item;
        const inner = icon ? <Icon>{icon}</Icon> : label;
        const maxHeight = size === "small" ? 24 : undefined;

        const style =
          toggle === value
            ? {
                color: textColor,
                backgroundColor: color,
                maxHeight,
              }
            : {
                maxHeight,
              };

        return (
          <ToggleButton
            key={value}
            size={size}
            value={value}
            aria-label="toggle-action"
            style={style}
          >
            {inner}
          </ToggleButton>
        );
      }),
    [size, items, toggle]
  );

  return (
    <ToggleButtonGroup
      value={toggle}
      exclusive
      onChange={handleChange}
      aria-label="row-toggle"
    >
      {mapToggles}
    </ToggleButtonGroup>
  );
}
