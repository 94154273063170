import { Box, Checkbox, TableCell } from "@material-ui/core";
import React, { useCallback } from "react";
import clsx from "clsx";
import { MpTableClasses } from "../../types/Table";
import useStyles from "./MpTableHeadSelectCell.styles";
import { TableToggleRowsSelectedProps } from "react-table";

interface Props extends TableToggleRowsSelectedProps {
  classes?: MpTableClasses;
  selection: "single" | "multiple";
}

export default function MpTableHeadSelectCell({
  classes: overrideClasses,
  selection,
  onChange,
  ...props
}: Props) {
  const classes = useStyles();

  const handleCheck = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      onChange?.(e);
    },
    [onChange]
  );

  const handlePropagation = useCallback(
    (e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) =>
      e.stopPropagation(),
    []
  );

  if (selection === "single") return <TableCell />;

  return (
    <TableCell
      className={clsx(classes.headSelectCell, overrideClasses?.headSelectCell)}
      onClick={handlePropagation}
    >
      <Box display="flex">
        <Checkbox
          color="primary"
          {...props}
          className={clsx(
            classes.cellCheckbox,
            overrideClasses?.headSelectCellCheckbox
          )}
          onChange={handleCheck}
        />
      </Box>
    </TableCell>
  );
}
