import React from "react";
import useStyles from "./MpTableGlobalActions.styles";
import { MpTableGlobalActionProps } from "../../types/Overridables";
import MpTableBulkActions from "../../components/MpTableBulkActions/MpTableBulkActions";
import { Box } from "@material-ui/core";
import MpTableGlobalFilter from "../../components/MpTableGlobalFilter/MpTableGlobalFilter";
import MpTableBreadcrumb from "../../components/MpTableBreadcrumb/MpTableBreadcrumb";

export default function MpTableGlobalActions({
  selectedRowIds,
  selectedRows,
  actions,
  methods,
  translations,
  onGlobalFilter,
  globalFilters,
  breadcrumbFilterData,
}: MpTableGlobalActionProps) {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      {!!actions && (
        <MpTableBulkActions
          selectedRowIds={selectedRowIds}
          selectedRows={selectedRows}
          actions={actions}
          methods={methods}
          translations={translations}
        />
      )}
      {!!breadcrumbFilterData && (
        <MpTableBreadcrumb
          onGlobalFilter={onGlobalFilter}
          translations={translations}
          globalFilters={globalFilters}
          data={breadcrumbFilterData}
        />
      )}
      <MpTableGlobalFilter
        onGlobalFilter={onGlobalFilter}
        translations={translations}
        globalFilters={globalFilters}
      />
    </Box>
  );
}
