import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export default function DatepickerArrowUp(props: SvgIconProps) {
  return (
    <SvgIcon
      fontSize="small"
      fill="none"
      width="10"
      height="5"
      viewBox="0 0 10 5"
      {...props}
      style={{ fill: "none", width: 10, height: 5, overflow: "visible" }}
    >
      <path
        d="M11.5 6.5L6.5 1.5L1.5 6.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
