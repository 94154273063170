import { InputBaseProps } from "@material-ui/core";
import {
  ColumnInstance,
  DefaultFilterTypes,
  Row,
  UseFiltersColumnProps,
  UseRowSelectRowProps,
  UseSortByColumnProps,
} from "react-table";
import {
  MpBulkActionMethods,
  MpActions,
  MpRowActionMethods,
  MpRowAction,
} from "./Actions";
import {
  MpBreadcrumbFilterData,
  MpFilter,
  MpFilterType,
  MpGlobalFilters,
} from "./Filter";
import { MpTableOverridablesMap } from "./Overridables";

export enum MpColumnFormat {
  currency = "currency",
  percent = "percent",
  date = "date",
  dateTime = "dateTime",
  map = "map",
}

export interface MpColumnFormatOptions {
  type: MpColumnFormat;
  // (Optional) Map values for rendering
  valueMap?:
    | Record<string | number, string | number | boolean>
    | Array<string | number | boolean>;
  modifier?: string;
}

export enum MpColumnRender {
  statusPill = "statusPill",
  toggle = "toggle",
  switch = "switch",
  button = "button",
  input = "input",
}

export interface MpColumnRenderOptions {
  type: MpColumnRender;
  // (Optional) Map values for rendering
  valueMap?:
    | Record<string | number, string | number | boolean>
    | Array<string | number | boolean>;
  // (Optional) Map values for rendering
  styleMap?:
    | Record<string | number, string | number | boolean>
    | Array<string | number | boolean>;
}

export interface RtMpColumnInstance
  extends ColumnInstance,
    UseSortByColumnProps<{}>,
    UseFiltersColumnProps<{}> {
  mpColumn: MpColumn;
}

export interface MpColumn {
  // Get value from the row using the key
  key: string;
  // Display value in the head cell
  label?: string;
  // (Optional) Filter type
  filter?: MpFilterType | MpFilter;
  // (Optional) Override footer enabled
  totals?: boolean;
  // (Optional) Override enable/disable sorting
  sort?: boolean;
  // (Optional) Format data before passing to the table
  format?: MpColumnFormat | MpColumnFormatOptions;
  // (Optional) Custom cell render type
  render?: MpColumnRender | MpColumnRenderOptions;
  // (Optional) Specify column action. If specified, no data is shown
  action?: MpRowAction;
  // (Optional) Input props that will be assigned to to the TextField component if field is being used
  inputProps?: Omit<
    InputBaseProps,
    "inputComponent" | "inputRef" | "onBlur" | "onChange"
  >;
  // (Optional) Set fixed column width
  width?: number | string;
  // (Optional) Set fixed column width to head column
  fixedHeadWidth?: boolean;
}

export interface MpTableType {
  // Array of MpColumns
  columns: MpColumn[];
  // Row identifier key
  rowIdKey: string;
  // Unique table identifier to use with storage caching
  storageIdentifier?: string;
  // Cache filters in local storage
  cacheFilters?: boolean;
  // Cache sorting in local storage
  cacheSorting?: boolean;
  // Cache global filter in local storage
  cacheGlobalFilter?: boolean;
  // Paginate locally
  localPagination?: boolean;
  // Sort locally
  localSorting?: boolean;
  // Filter locally
  localFiltering?: boolean;
  // Disable pagination
  disablePagination?: boolean;
  // Disable sorting
  disableSorting?: boolean;
  // Enable global actions
  enableGlobalActions?: boolean;
  // Selection
  selection?: "single" | "multiple";
  // Footer
  totals?: boolean;
  // Sticky footer - HIC SUNT DRACONES !!!
  stickyTotals?: boolean;
  // Number of rows per page
  pageSize?: number;
  // Conndensed or regular spacing
  size?: "small" | "medium";
  // Where do display filters
  filterVariant?: "head" | "pill";
  // Bulk actions
  bulkActions?: MpActions;
  // For values formatters
  locale?: string;
  // Highlight rows based on the boolean value of these fields
  highlights?: MpTableHighlights;
  // Clear column filters from head cell
  clearColumnFilters?: boolean;
}

export interface MpTableHighlights {
  inactive?: string;
  primary?: string;
  secondary?: string;
}

export interface MpSortRule {
  // Sort using this key
  key: string;
  // Ascending if falsy, descending if true
  desc?: boolean;
}

export interface MpTableDataArguments {
  // Number of items to take
  limit?: number;
  // Start from this item (cursor / item index /etc.)
  cursor?: number;
  // Fetch only the columns that are visible
  columns: string[];
  // Sorting rules (can be multiple)
  sort?: MpSortRule[];
  // Filters object
  filters?: Record<string, any>;
  // Global filter value
  globalFilters?: MpGlobalFilters;
}

export type OnGetData = (args: MpTableDataArguments) => void;

export interface MpTableProps<ItemType = any>
  extends MpTableType,
    MpTableInputProps<ItemType> {}

interface MpAsyncGetMethodArguments {
  search?: string;
}

export type MpAsyncGetMethod = (
  options?: MpAsyncGetMethodArguments
) => Promise<any>;

export interface MpTableInputProps<ItemType = any> {
  data?: ItemType[];
  onGetData: OnGetData;
  totalsData?: Partial<Record<keyof ItemType, string>>;
  loading?: boolean;
  dataCount?: number;
  classes?: MpTableClasses;
  translations?: MpTableTranslations;
  overridables?: Partial<MpTableOverridablesMap>;
  bulkMethods?: MpBulkActionMethods;
  rowMethods?: MpRowActionMethods;
  asyncGetMethods?: Record<string, MpAsyncGetMethod>;
  onRowClick?: (row?: Row & UseRowSelectRowProps<{}>) => void;
  breadcrumbFilterData?: MpBreadcrumbFilterData;
  onEdit?: (data: ItemType[]) => void;
  onRowsSelected?: (selectedRows: Array<Row<{}>>) => void;
  emptyChildren?: React.ReactNode;
  loadingComponent?: React.ReactNode;
  clearFiltersEvent?: string[];
  resetSelectedRows?: boolean;
}

export interface MpTableClasses {
  tableWrapper?: string;
  table?: string;
  headSelectCellCheckbox?: string;
  headSelectCell?: string;
  selectCell?: string;
  selectCellCheckbox?: string;
  totalsSelectCell?: string;
  highlightedInactive?: string;
  highlightedPrimary?: string;
  highlightedSecondary?: string;
  row?: string;
  rowSelected?: string;
  hoveredCellWithFilters?: string;
}

export interface MpTableTranslations {
  of?: string;
  total?: string;
  previousPage?: string;
  nextPage?: string;
  selected?: string;
  actions?: string;
  from?: string;
  to?: string;
  all?: string;
  clear?: string;
  search?: string;
  allTime?: string;
  breadcrumbs?: { [key: string]: string | undefined };
}

export type MpFilterTypes = DefaultFilterTypes;

export type MpColumnFormatter = (params: {
  value: any;
  locale?: string;
  options?: MpColumnFormatOptions;
}) => string | number | null;
