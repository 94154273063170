import { makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
  naked: {
    width: "100%",
  },
  emptyAnchor: {
    cursor: "pointer",
    userSelect: "none",
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
}));
