import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  root: {
    padding: 0,
    maxHeight: 29,
    width: "100%",
    fontSize: "inherit",
  },
  input: {
    height: "auto",
    maxHeight: 29,
    padding: 0,
    fontSize: "inherit",
  },
}));
