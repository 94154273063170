import { makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
  wrapper: {
    transition: `${theme.transitions.create(["max-width"], {
      duration: theme.transitions.duration.complex,
    })}`,
  },
  iconSpacer: {
    marginRight: 10,
  },
  menuPaper: {
    padding: "10px 0",
    marginTop: 4,
  },
  menuItem: {
    paddingTop: 14,
    paddingBottom: 0,
    paddingRight: 0,
    minWidth: 150,
  },
  overlay: {
    zIndex: 1,
  },
  itemDivider: {
    borderBottom: "rgba(0, 0, 0, 0.1) solid 1px",
    marginLeft: 20,
    paddingRight: 20,
    width: "100%",
    paddingBottom: 14,
  },
  noBorder: {
    borderBottom: 0,
  },
}));
