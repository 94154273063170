import { toPercent } from "./Numbers/Numbers";
import { toCurrency } from "./Currency/Currency";
import { formatDate, formatDatetime } from "./Date/Date";
import { MpColumnFormat, MpColumnFormatter } from "./../types/Table";
import { mapFormatter } from "./Map/Map";

export function rawValueKey(key: string) {
  return `__raw_${key}`;
}

export function getRawValue(item: any, key: string) {
  return item[rawValueKey(key)] ?? item[key];
}

export const formatMap: Record<MpColumnFormat, MpColumnFormatter> = {
  [MpColumnFormat.currency]: toCurrency,
  [MpColumnFormat.percent]: toPercent,
  [MpColumnFormat.date]: formatDate,
  [MpColumnFormat.dateTime]: formatDatetime,
  [MpColumnFormat.map]: mapFormatter,
};
