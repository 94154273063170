import {
  Grid,
  Typography,
  List,
  ListItem,
  Divider,
  Box,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { MpBreadcrumbFilterData } from "../../types/Filter";
import { MpTableTranslations } from "../../types/Table";

interface IProps {
  selectedKey: string;
  items: MpBreadcrumbFilterData[];
  onMouseEnter: (key: string, id: string, name: string) => void;
  onClick: (selectedKey: string) => void;
  selectedId: string | undefined;
  translations?: MpTableTranslations;
}

export default function BreadcrumbList({
  selectedKey,
  items,
  onMouseEnter,
  selectedId,
  translations,
  onClick,
}: IProps) {
  const breadcrumbMap = useMemo(
    () =>
      items.map((item) => (
        <ListItem
          button
          key={item.id}
          onMouseEnter={() =>
            onMouseEnter(
              selectedKey,
              item.id as string,
              translations?.breadcrumbs?.[item.name as string] ??
                (item.name as string)
            )
          }
          onClick={() => onClick(selectedKey)}
          selected={selectedId === item.id}
        >
          <Typography color="textSecondary" variant="body2">
            {translations?.breadcrumbs?.[item.name as string] ?? item.name}
          </Typography>
        </ListItem>
      )),
    [items, onClick, onMouseEnter, selectedId, selectedKey, translations]
  );

  return (
    <Grid component={Box} minWidth="200px" item>
      <Box paddingX={2} paddingY={1}>
        <Typography color="textPrimary" variant="subtitle2">
          {translations?.breadcrumbs?.[selectedKey] ?? selectedKey}
        </Typography>
      </Box>
      <Divider />
      <List>{breadcrumbMap}</List>
    </Grid>
  );
}
