import React from "react";

import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const FilterArrowUp = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <svg viewBox="0 0 24 24">
      <path
        d="M11 11L16.76 3.62C16.9224 3.41153 16.9957 3.14726 16.9638 2.88493C16.932 2.6226 16.7976 2.38355 16.59 2.22C16.4221 2.08505 16.2152 2.00789 16 2H1.99999C1.77443 2.00131 1.55593 2.07884 1.37999 2.22C1.17243 2.38355 1.03804 2.6226 1.00618 2.88493C0.974328 3.14726 1.0476 3.41153 1.20999 3.62L6.99999 11V16.87C6.9819 17.0213 6.99862 17.1747 7.04888 17.3186C7.09914 17.4624 7.18161 17.5929 7.28999 17.7L9.28999 19.7C9.47736 19.8863 9.73081 19.9908 9.99499 19.9908C10.2592 19.9908 10.5126 19.8863 10.7 19.7C10.8102 19.5938 10.8945 19.4638 10.9465 19.3199C10.9985 19.176 11.0168 19.0221 11 18.87V11ZM13 21L18 16L23 21H13Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
