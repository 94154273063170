import { MpBreadcrumbFilterData } from "../../types/Filter";

export const getNestedElementsMapReduce = (
  data: MpBreadcrumbFilterData,
  selection: {
    [key: string]: {
      id: string | undefined;
      name: string | undefined;
    };
  }
): {
  [key: string]: MpBreadcrumbFilterData[];
} | void => {
  if (!!data)
    return Object.entries(data).reduce(
      (accumulator, [key, value]) => {
        if (Array.isArray(value)) {
          accumulator[key] = value;
          if (selection[key]?.id !== undefined) {
            const filteredValue = value.filter(
              (breadcrumb) => breadcrumb.id === selection[key]?.id
            );
            if (filteredValue.length > 0) {
              return {
                ...accumulator,
                ...getNestedElementsMapReduce(filteredValue[0], selection),
              };
            }
          }
        }
        return accumulator;
      },
      {} as {
        [key: string]: MpBreadcrumbFilterData[];
      }
    );
};
