import MpTableHeadFilter from "./MpTableHeadFilter/MpTableHeadFilter";
import MpTablePaginator from "./MpTablePaginator/MpTablePaginator";
import { MpTableOverridablesMap } from "../types/Overridables";
import MpTableHeadIndicator from "./MpTableHeadIndicator/MpTableHeadIndicator";
import MpTableGlobalActions from "./MpTableGlobalActions/MpTableGlobalActions";

export const defaultOverridables: Omit<
  MpTableOverridablesMap,
  "renderers" | "filters" | "filterFunctions"
> = {
  paginator: MpTablePaginator,
  headFilter: MpTableHeadFilter,
  headIndicator: MpTableHeadIndicator,
  globalActions: MpTableGlobalActions,
};
