import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  parent: {
    borderRadius: "inherit",
  },
  wrapper: {
    maxWidth: "100%",
    overflowX: "auto",
    borderTopLeftRadius: "inherit",
    borderTopRightRadius: "inherit",
  },
  table: {
    borderTopLeftRadius: "inherit",
    borderTopRightRadius: "inherit",
    overflow: "hidden",
  },
  stickyTotalsWrapper: {
    position: "relative",
    overflow: "auto",
    zIndex: 1,
  },
  renderedCell: {
    paddingTop: 2,
    paddingBottom: 2,
  },
});
