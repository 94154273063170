import { ComponentType } from "react";
import { MpFilterTypes } from "../types/Table";
import Select from "./Select/Select";
import Text from "./Text/Text";
import { FilterFunction, MpFilterProps, MpFilterType } from "../types/Filter";
import SearchSelect from "./SearchSelect/SearchSelect";
import NumberRange from "./NumberRange/NumberRange";
import { getRawValue } from "../formats/Formats";

const matchArray: FilterFunction = (rows, columnIds, filterValue) => {
  if (!Array.isArray(filterValue) || filterValue.length < 1) {
    return rows;
  } else {
    return rows.filter(
      (row) =>
        !!columnIds.find((id) =>
          filterValue.includes(getRawValue(row.original, id))
        )
    );
  }
};

const matchRange: FilterFunction = (rows, columnIds, filterValue) => {
  if (filterValue == null || typeof filterValue !== "object") {
    return rows;
  }
  return rows.filter(
    (row) =>
      !!columnIds.find((id) => {
        if (
          filterValue?.min != null &&
          filterValue?.min !== "" &&
          +getRawValue(row.original, id) < +filterValue?.min
        ) {
          return false;
        } else if (
          filterValue?.max != null &&
          filterValue?.max !== "" &&
          +getRawValue(row.original, id) > +filterValue?.max
        ) {
          return false;
        }
        return true;
      })
  );
};

export const filterMap: Record<MpFilterType, ComponentType<MpFilterProps>> = {
  [MpFilterType.select]: Select,
  [MpFilterType.searchSelect]: SearchSelect,
  [MpFilterType.text]: Text,
  [MpFilterType.numberRange]: NumberRange,
};

// export type DefaultFilterTypes =
//     | 'text'
//     | 'exactText'
//     | 'exactTextCase'
//     | 'includes'
//     | 'includesAll'
//     | 'exact'
//     | 'equals'
//     | 'between';

export const filterTypeMap: Record<
  MpFilterType,
  MpFilterTypes | FilterFunction
> = {
  [MpFilterType.select]: matchArray,
  [MpFilterType.searchSelect]: matchArray,
  [MpFilterType.text]: "text",
  [MpFilterType.numberRange]: matchRange,
};
