import React, { useCallback, useContext, useMemo } from "react";
import {
  Button as MUIButton,
  ButtonProps,
  Icon,
  IconButton,
  IconButtonProps,
} from "@material-ui/core";
import { CellProps } from "react-table";
import { RtMpColumnInstance } from "../../../types/Table";
import {
  getColumnAction,
  handleRowAction,
} from "../../../utils/Actions/Actions";
import { MetaContext } from "../../../utils/MetaContext/MetaContext";
import { MpRowActionMethods } from "../../../types/Actions";
import useStyles from "./Button.styles";

export function Button(props: CellProps<{}>) {
  const classes = useStyles();
  const { column, row } = props;
  const { rowMethods, size } = useContext(MetaContext);
  const action = useMemo(() => getColumnAction(column as RtMpColumnInstance), [
    column,
  ]);
  const { color, icon, label, variant } = action;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleRowAction({
        methods: rowMethods as MpRowActionMethods,
        slug: action.slug,
        row: row,
        rowId: row.id,
      });
    },
    [row, action, rowMethods]
  );

  if (icon) {
    return (
      <IconButton
        color={color as IconButtonProps["color"]}
        onClick={handleClick}
      >
        <Icon>icon</Icon>
      </IconButton>
    );
  }

  return (
    <MUIButton
      size={size}
      color={color as ButtonProps["color"]}
      variant={variant as ButtonProps["variant"]}
      onClick={handleClick}
      classes={{ sizeSmall: classes.small }}
    >
      {label}
    </MUIButton>
  );
}
