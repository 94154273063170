import { useCallback, useEffect, useMemo, useState } from "react";
import { MpColumnFormatOptions, MpColumn } from "../../types/Table";
import { formatMap, rawValueKey } from "./../../formats/Formats";

export function useDataFormatters<ItemType>(
  data: ItemType[],
  columns: MpColumn[],
  locale?: string
): [
  ItemType[],
  (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
    id: string | number
  ) => void
] {
  const [dataState, setDataState] = useState<ItemType[]>([]);

  const formattedData = useMemo(() => {
    const formattableColumns: MpColumn[] = columns.filter((col) => col.format);
    return data.map((item: any) =>
      formattableColumns.reduce(
        (acc, column) => {
          const { key, format } = column;
          acc[rawValueKey(key)] = item[rawValueKey(key)];
          acc[key] = formatMap[
            (format as MpColumnFormatOptions)?.type ?? format
          ]({
            value: item[key],
            locale,
            options: typeof format === "string" ? undefined : format,
          });
          return acc;
        },
        { ...item }
      )
    );
  }, [data, columns, locale]);

  useEffect(() => setDataState(formattedData), [formattedData]);

  const changeData = useCallback(
    (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      index: number,
      id: string | number
    ) => {
      const { value } = e.target;
      setDataState((prevState) => {
        const stateCopy: ItemType[] = [...prevState];
        stateCopy[index][id] = value;
        return stateCopy;
      });
    },
    []
  );

  return [dataState, changeData];
}
