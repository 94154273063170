import clsx from "clsx";
import React, { useMemo } from "react";
import useStyles from "./MpTablePaginator.styles";

import {
  Box,
  Tooltip,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { MpTablePaginatorProps } from "../../types/Overridables";

export default function MpTablePaginator({
  pageIndex,
  previousPage,
  itemCount,
  nextPage,
  pageCount,
  pageSize,
  loading,
  translations,
  selectedCount,
}: MpTablePaginatorProps) {
  const classes = useStyles();
  const hasPrevious = useMemo(() => pageIndex > 0, [pageIndex]);
  const hasNext = useMemo(
    () => (pageCount == null ? true : pageIndex < pageCount - 1),
    [pageIndex, pageCount]
  );
  const infoText = useMemo(
    () =>
      [
        itemCount != null
          ? `${itemCount} ${translations?.total ?? "total"}`
          : null,
        selectedCount != null && selectedCount > 0
          ? `${selectedCount} ${translations?.selected ?? "selected"}`
          : null,
      ]
        .filter((item) => item != null)
        .join(" • "),
    [itemCount, selectedCount, translations]
  );

  return (
    <Box paddingY={1} paddingX={2} className={clsx(classes.wrapper)}>
      <Typography color="textSecondary" variant="body2">
        {infoText}
      </Typography>
      {loading && (
        <Box
          paddingX={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size="1.5em" data-qa="TableProgress" />
        </Box>
      )}
      <Box className={classes.spacer} />
      <Box paddingX={1}>
        <Typography color="textSecondary" variant="body2">
          {pageIndex * pageSize + 1} - {(pageIndex + 1) * pageSize}
          {pageCount != null ? ` ${translations?.of ?? "of"} ${pageCount}` : ""}
        </Typography>
      </Box>
      <Tooltip title={translations?.previousPage ?? "Previous"}>
        <div>
          <IconButton
            size="small"
            onClick={previousPage}
            disabled={!hasPrevious}
          >
            <ChevronLeft />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title={translations?.nextPage ?? "Next"}>
        <div>
          <IconButton size="small" onClick={nextPage} disabled={!hasNext}>
            <ChevronRight />
          </IconButton>
        </div>
      </Tooltip>
    </Box>
  );
}
