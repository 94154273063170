import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export default function BreadcrumbSeparator(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 16.5L15.5 11.5L10.5 6.5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
}
