import { makeStyles, Theme } from "@material-ui/core/styles";

const width = 18;

export default makeStyles((theme: Theme) => ({
  iconWrapper: {
    display: "inline-flex",
    maxHeight: "1em",
    justifyContent: "center",
    maxWidth: width,
    width: width,
    minWidth: width,
    alignItems: "center",
  },
  clearIcon: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 19,
    height: 19,
    borderRadius: "3px",
    position: "absolute",
    left: 0,
  },
}));
