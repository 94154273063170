import { RtMpColumnInstance } from "./../../types/Table";
import {
  MpBulkActionClickParams,
  MpBulkActionParameters,
  MpRowActionHandlerParams,
  MpRowAction,
  MpRowActionParameters,
} from "./../../types/Actions";

export const getColumnAction = (column: RtMpColumnInstance) => {
  const action = column?.mpColumn?.action;
  if (!action) throw new Error("Missing action property from column.");

  return action as MpRowAction;
};

export const handleRowAction = (params: MpRowActionHandlerParams) => {
  const { row, rowId, slug, value, methods } = params;
  if (!methods)
    throw new Error(
      `No row methods provided. Please provide them in order to use them.`
    );
  const method = methods[slug];
  const methodsParams: MpRowActionParameters = {
    value: value as any,
    row,
    rowId,
  };
  if (method) method(methodsParams);
  else
    throw new Error(
      `A method for action '${slug}' does not exist. Either add it or remove this action.`
    );
};

export const handleBulkAction = (params: MpBulkActionClickParams) => {
  const { methods, selectedRowIds, selectedRows, slug } = params;
  const method = methods[slug];
  const methodsParams: MpBulkActionParameters = {
    selectedRows,
    selectedIds: selectedRowIds,
  };
  if (method) method(methodsParams);
  else
    throw new Error(
      `A method for action '${slug}' does not exist. Either add it or remove this action.`
    );
};
