import { InputBaseComponentProps } from "@material-ui/core";
import React, { forwardRef, useCallback } from "react";

export const NumberInput = forwardRef<HTMLInputElement>(
  (
    {
      onChange,
      inputRef,
      decimalPoint = 2,
      negative = true,
      ...props
    }: InputBaseComponentProps & {
      decimalPoint?: number;
      negative?: boolean;
    },
    ref
  ) => {
    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        let val = event.target.value;
        // Allow only leading -
        if (negative) {
          val = val.replace(/-/g, (m, i) => (i > 0 ? "" : m));
        } else {
          val = val.replace(/-/g, "");
        }
        // Remove additional . or ,
        if (decimalPoint > 0) {
          val = val.replace(/[.,]/g, (m, i: number, s: string) =>
            s.indexOf(s.match(/[.,]/g)?.[0] ?? ".") !== i || i === 0 ? "" : m
          );
        } else {
          val = val.replace(/[.,]/g, "");
        }
        // Leave only numbers and . , -
        val = val.replace(/[^\d.,-]/g, "");
        if (decimalPoint > 0) {
          val = val.replace(/[.,]\d+/g, (m) => m.substr(0, decimalPoint + 1));
        }
        // Write to event
        event.target.value = val;
        if (typeof onChange === "function") {
          onChange(event);
        }
      },
      [onChange, decimalPoint, negative]
    );
    const { onBlur } = props;
    const handleBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value != null && event.target.value !== "") {
          event.target.value = (+event.target.value).toFixed(decimalPoint);
        }
        if (typeof onBlur === "function") {
          onBlur(event);
        }
      },
      [decimalPoint, onBlur]
    );
    return (
      <input
        ref={ref ?? inputRef}
        {...props}
        onBlur={handleBlur}
        onChange={handleChange}
        inputMode="decimal"
      />
    );
  }
);
