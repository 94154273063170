import { darken, lighten, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
  renderedCell: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  highlightedPrimary: {
    background:
      theme.palette.type === "dark"
        ? darken(theme.palette.primary.main, 0.7)
        : lighten(theme.palette.primary.main, 0.9),
  },
  highlightedSecondary: {
    background:
      theme.palette.type === "dark"
        ? darken(theme.palette.secondary.main, 0.7)
        : lighten(theme.palette.secondary.main, 0.9),
  },
  highlightedInactive: {
    background: theme.palette.background.default,
    color: theme.palette.text.secondary,
  },
  cell: {
    color: "inherit",
  },
  pointer: {
    cursor: "pointer",
  },
}));
