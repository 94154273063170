import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  totalsSelectCell: {
    paddingRight: 0,
    "&:not(:last-child)": {
      borderRight: "none !important",
      boxShadow: "none !important",
    },
  },
});
