import { TableFooter, TableFooterProps } from "@material-ui/core";
import React, {
  PropsWithChildren,
  RefObject,
  useLayoutEffect,
  useRef,
} from "react";
import useStyles from "./MpTableStickyTotalsFooter.styles";

const win = typeof window === "undefined" ? null : window;

export default function MpTableStickyTotalsFooter({
  children,
  bodyRef,
  ...props
}: PropsWithChildren<TableFooterProps> & {
  bodyRef: RefObject<HTMLTableSectionElement>;
}) {
  const classes = useStyles();
  const ref = useRef<HTMLTableSectionElement>(null);

  useLayoutEffect(() => {
    let timeout: any = null;
    let queuedUpdate: boolean = false;
    let transformValue: number = 0;
    const fps = 120;

    // Update the styles
    const update = () => {
      const calculatedTransform = -(
        (bodyRef?.current?.getBoundingClientRect().bottom ?? 0) -
        window.innerHeight +
        (ref?.current?.offsetHeight ?? 0)
      );
      const transform =
        calculatedTransform >= 0 ? "" : `translateY(${calculatedTransform}px)`;
      if (ref.current) {
        ref.current.style.transform = transform;
        if (calculatedTransform < 0 && transformValue >= 0) {
          ref.current.classList.add(classes.raised);
        } else if (calculatedTransform >= 0 && transformValue < 0) {
          ref.current.classList.remove(classes.raised);
        }
      }
      transformValue = calculatedTransform;
      timeout = setTimeout(() => {
        timeout = null;
        if (queuedUpdate) {
          queuedUpdate = false;
          update();
        }
      }, 1000 / fps);
    };

    // Throttle the update
    const triggerUpdate = () => {
      if (timeout === null) {
        update();
      } else {
        queuedUpdate = true;
      }
    };

    // Set event listeners
    win?.addEventListener("scroll", triggerUpdate);
    win?.addEventListener("resize", triggerUpdate);
    triggerUpdate();

    // Remove event listeners
    return () => {
      win?.removeEventListener("scroll", triggerUpdate);
      win?.removeEventListener("resize", triggerUpdate);
    };
  }, [bodyRef, classes.raised, children]);

  return (
    <TableFooter {...props} className={classes.footer} ref={ref}>
      {children}
    </TableFooter>
  );
}
