import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export default function DatepickerArrowDown(props: SvgIconProps) {
  return (
    <SvgIcon
      fontSize="small"
      width="10"
      height="5"
      viewBox="0 0 10 5"
      {...props}
      style={{ fill: "none", width: 10, height: 5, overflow: "visible" }}
    >
      <path
        d="M1.5 1.5L6.5 6.5L11.5 1.5"
        stroke="#69717D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
