import { Box, Typography } from "@material-ui/core";
import useStyles from "./MpDatepicker.styles";
import React, { useCallback, useState } from "react";
import Check from "../../icons/Check";
import { MpTableTranslations } from "../../types/Table";
import { FromToFilterProps } from "../../types/Datepicker";

interface Props {
  onSelectAll: () => void;
  date: string | null;
  translations?: MpTableTranslations;
  FromToFilter?: React.ComponentType<FromToFilterProps>;
  filterProps: FromToFilterProps;
}

export default function MpDatepickerHeader({
  date,
  onSelectAll,
  translations,
  FromToFilter,
  filterProps,
}: Props) {
  const classes = useStyles();

  const [isFromTo, setIsFromTo] = useState<boolean>(false);

  const switchView = useCallback(() => setIsFromTo(true), []);

  if (isFromTo && !!FromToFilter) return <FromToFilter {...filterProps} />;

  return (
    <Box className={classes.header}>
      {!date && <Check className={classes.iconContainer} />}
      <Typography
        onClick={onSelectAll}
        classes={{ root: classes.headerAction }}
        variant="body2"
      >
        {translations?.allTime ?? "All time"}
      </Typography>
      {!!FromToFilter && (
        <Typography
          onClick={(e) => {
            e.stopPropagation();
            switchView();
          }}
          classes={{ root: classes.headerAction }}
          variant="body2"
        >{`${translations?.from ?? "From"} - ${
          translations?.to ?? "To"
        }`}</Typography>
      )}
    </Box>
  );
}
