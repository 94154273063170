import { useMemo } from "react";
import { CellProps } from "react-table";
import { MpColumn, MpColumnRenderOptions } from "../../types/Table";

export function useRendererOptions({ cell }: CellProps<{}>) {
  const column: MpColumn | undefined = (cell.column as any).mpColumn;
  const renderOptions: MpColumnRenderOptions | undefined = useMemo(
    () =>
      typeof column?.render === "string"
        ? { type: column.render }
        : column?.render,
    [column?.render]
  );
  const style = useMemo(
    () => renderOptions?.styleMap?.[cell.value ?? ""] ?? undefined,
    [renderOptions, cell]
  );
  const value = useMemo(
    () => renderOptions?.valueMap?.[cell.value ?? ""] ?? cell.value,
    [renderOptions, cell]
  );

  const columnId = useMemo(() => cell.column.id, [cell.column.id]);

  const columnIndex = useMemo(() => cell.row.index, [cell.row.index]);

  const inputProps = useMemo(() => column?.inputProps, [column?.inputProps]);

  return {
    column,
    renderOptions,
    style,
    value,
    columnId,
    columnIndex,
    inputProps,
  };
}
