import { Popover } from "@material-ui/core";
import React, { useCallback, useEffect, Dispatch, SetStateAction } from "react";
import { MpTableOverridablesMap } from "../../types/Overridables";
import { RtMpColumnInstance } from "../../types/Table";

interface Props {
  column: RtMpColumnInstance;
  anchorEl?: Element | null;
  canSort?: boolean;
  onClose(): void;
  isSortedDesc?: boolean;
  doSorting(): void;
  overridables: MpTableOverridablesMap;
  clearFiltersEvent?: string[];
  value?: any;
  setValue: Dispatch<SetStateAction<any | undefined>>;
}

export default function MpTableHeadCellOptions({
  column,
  anchorEl,
  canSort,
  onClose,
  isSortedDesc,
  doSorting,
  overridables,
  clearFiltersEvent,
  value,
  setValue,
}: Props) {
  const doFiltering = useCallback(
    (immediateValue: any = value) => {
      column.setFilter(immediateValue);
      setValue(immediateValue);
      onClose();
    },
    [value, column, onClose]
  );

  useEffect(() => {
    if (
      clearFiltersEvent != null &&
      (!clearFiltersEvent?.length || clearFiltersEvent?.includes(column.id))
    ) {
      setValue(null);
    }
    // eslint-disable-next-line
  }, [clearFiltersEvent]);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl ?? undefined}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <overridables.headFilter
        canSort={canSort}
        isSortedDesc={isSortedDesc}
        overridables={overridables}
        doSorting={doSorting}
        doFiltering={doFiltering}
        value={value}
        setValue={setValue}
        column={column}
      />
    </Popover>
  );
}
