import { TableCell, TableFooter, TableRow } from "@material-ui/core";
import React, { RefObject, useCallback } from "react";
import { HeaderGroup } from "react-table";
import { MpTableClasses } from "../../types/Table";
import MpTableStickyTotalsFooter from "../MpTableStickyTotalsFooter/MpTableStickyTotalsFooter";
import useStyles from "./MpTableTotals.styles";
import clsx from "clsx";

interface Props {
  footerGroups: HeaderGroup<{}>[];
  hasSelection?: boolean;
  stickyTotals?: boolean;
  classes?: MpTableClasses;
  bodyRef: RefObject<HTMLTableSectionElement>;
}

export default function MpTableTotals({
  footerGroups,
  hasSelection,
  stickyTotals,
  classes: overrideClasses,
  bodyRef,
}: Props) {
  const classes = useStyles();

  const handleFixedWidth = useCallback((column) => {
    const cellWidth = column.mpColumn?.width;
    return cellWidth
      ? {
          minWidth: cellWidth,
          width: cellWidth,
          maxWidth: cellWidth,
        }
      : undefined;
  }, []);

  const groups = footerGroups.map((group) => (
    <TableRow {...group.getFooterGroupProps()}>
      {hasSelection && (
        <TableCell
          className={clsx(
            classes.totalsSelectCell,
            overrideClasses?.totalsSelectCell
          )}
        />
      )}
      {group.headers.map((column) => (
        <TableCell
          style={handleFixedWidth(column)}
          {...column.getFooterProps()}
        >
          {(column as any).Footer != null && column.render("Footer")}
        </TableCell>
      ))}
    </TableRow>
  ));

  return stickyTotals ? (
    <MpTableStickyTotalsFooter bodyRef={bodyRef}>
      {groups}
    </MpTableStickyTotalsFooter>
  ) : (
    <TableFooter>{groups}</TableFooter>
  );
}
