import { Grid } from "@material-ui/core";
import React, { useMemo } from "react";
import { MpControl, MpFormProps } from "../../types/Form";
import { defaultMessages } from "../../utils/Control/Control";
import MpFormControl from "../MpFormControl/MpFormControl";
import { get } from "react-hook-form";

const defaultSpan = { xs: 12 };

export default function MpForm({
  controls,
  size,
  layout,
  variant,
  locale,
  useFormMethods,
  overridables,
  defaultMessages: partialDefaultMessages,
}: MpFormProps) {
  const { register, errors, control: hookFormControl } = useFormMethods;
  const messages = useMemo(
    () => ({
      ...defaultMessages,
      ...(partialDefaultMessages ?? {}),
    }),
    [partialDefaultMessages]
  );

  return (
    <Grid container={true} spacing={2}>
      {controls.map((control: MpControl, i: number) => (
        <Grid
          item={true}
          key={i}
          xs={12}
          {...((control.span ?? defaultSpan) as any)}
        >
          <MpFormControl
            register={register}
            control={control}
            locale={locale}
            hookFormControl={hookFormControl}
            defaultMessages={messages}
            error={get(errors ?? {}, control?.key ?? "")}
            errors={errors}
            size={size}
            layout={layout}
            variant={variant}
            overridables={overridables}
          />
        </Grid>
      ))}
    </Grid>
  );
}
