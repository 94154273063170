import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  dropdownIconRoot: {
    width: 13,
    height: 8,
    marginLeft: 7,
    cursor: "pointer",
  },

  seperator: {
    margin: "0 10px",
  },
  pointer: {
    cursor: "pointer",
  },
}));
