import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Paper,
  Popover,
} from "@material-ui/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import BreadcrumbFilterDown from "../../icons/BreadcrumbFilterDown";
import BreadcrumbSeparator from "../../icons/BreadcrumbSeparator";
import { MpBreadcrumbFilterData } from "../../types/Filter";
import { MpTableGlobalFilterProps } from "../../types/Overridables";
import { getNestedElementsMapReduce } from "../../utils/Breadcrumbs/Breadcrumbs";
import BreadcrumbList from "./BreadcrumbList";
import useStyles from "./MpTableBreadcrumb.styles";

interface IProps extends MpTableGlobalFilterProps {
  data: MpBreadcrumbFilterData;
}

export default function MpTableBreadcrumb({
  data,
  translations,
  onGlobalFilter,
  globalFilters,
}: IProps) {
  const classes = useStyles();
  const [selection, setSelection] = useState<{
    [key: string]: {
      id: string | undefined;
      name: string | undefined;
    };
  }>({});
  const [items, setItems] = useState<{
    [key: string]: MpBreadcrumbFilterData[];
  }>({});
  const [breadcrumbs, setBreadcrumbs] = useState<{
    [key: string]: {
      id: string | undefined;
      name: string | undefined;
    };
  }>(globalFilters?.breadcrumb ?? {});
  const containerRef = useRef<HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = useMemo(() => !!anchorEl, [anchorEl]);
  const popoverId = useMemo(
    () => (open ? "breadcrumb-popover" : undefined),
    [open]
  );

  const updateNestedElements = useCallback(
    (data: MpBreadcrumbFilterData) => {
      setItems({ ...getNestedElementsMapReduce(data, selection) });
    },
    [selection]
  );

  const handleBreadcrumbHover = useCallback(
    (selectedKey: string, id: string, name: string) => {
      const itemKeys = Object.keys(items);
      const itemEntries = Object.entries(items);
      const selectionKeys = Object.keys(selection);
      const selectionDepth = itemKeys.indexOf(selectedKey);

      if (selectionDepth !== 0) {
        let selectionObject = {};
        let selectedPath: string[] =
          breadcrumbs[selectedKey]?.id === id ? [selectedKey] : [];
        let prevSelectedObject = {};
        for (let i = 0; i < selectionDepth; i++) {
          const [key, value] = itemEntries[i];
          if (breadcrumbs[key]?.id === selection[key].id) {
            selectedPath = [...selectedPath, key];
          } else selectedPath = [];
          if (selectionKeys.indexOf(key) !== -1) {
            prevSelectedObject = {
              ...prevSelectedObject,
              [key]: {
                id: selection[key].id,
                name: selection[key].name,
              },
            };
          }
          if (selectionKeys.indexOf(key) === -1) {
            selectionObject = {
              ...selectionObject,
              [key]: {
                id: value[0].id,
                name: value[0].name,
              },
            };
          }
        }
        if (selectedPath?.length - 1 === selectionDepth)
          prevSelectedObject = breadcrumbs;
        setSelection(() => {
          return {
            ...prevSelectedObject,
            ...selectionObject,
            [selectedKey]: {
              id,
              name,
            },
          };
        });
      } else {
        setSelection(() => {
          if (breadcrumbs[selectedKey]?.id === id) return breadcrumbs;
          return {
            [selectedKey]: {
              id,
              name,
            },
          };
        });
      }
    },
    [items, selection, breadcrumbs]
  );

  const handleBreadcrumbClick = useCallback(
    (selectedKey: string, isLastClick?: boolean) => {
      const selectionEntries = Object.entries(selection);
      const selectionDepth = Object.keys(selection).indexOf(selectedKey) + 1;
      if (isLastClick) {
        setAnchorEl(containerRef.current);
        setSelection(breadcrumbs);
      } else {
        const slicedArray = selectionEntries.slice(0, selectionDepth);
        const slicedObject = Object.fromEntries(slicedArray);
        onGlobalFilter({
          key: "breadcrumb",
          value: slicedObject,
        });
        setBreadcrumbs(slicedObject);
        setSelection(slicedObject);
        setAnchorEl(null);
      }
    },
    [breadcrumbs, onGlobalFilter, selection]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setSelection(breadcrumbs);
  }, [breadcrumbs]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateNestedElements(data), [selection, data]);

  useEffect(() => {
    if (Object.keys(selection)?.length < 1) setSelection(breadcrumbs);
  }, [breadcrumbs, selection]);

  useEffect(
    () => setBreadcrumbs(globalFilters?.breadcrumb ?? {}),
    [globalFilters?.breadcrumb]
  );

  const breadcrumbGridMap = useMemo(() => {
    const entries = Object.entries(items);
    const gridItems = [];
    for (const [key, value] of entries) {
      gridItems.push(
        <BreadcrumbList
          key={key}
          selectedKey={key}
          items={value}
          onMouseEnter={handleBreadcrumbHover}
          onClick={handleBreadcrumbClick}
          selectedId={selection[key]?.id}
          translations={translations ?? {}}
        />
      );
    }

    return gridItems;
  }, [
    handleBreadcrumbClick,
    handleBreadcrumbHover,
    items,
    selection,
    translations,
  ]);

  const breadcrumbSelectionMap = useMemo(() => {
    const entries = Object.entries(breadcrumbs);
    const lastItem = entries[entries.length - 1];
    const breadcrumbItems = [
      <Link
        color="textPrimary"
        variant="h6"
        onClick={() => handleBreadcrumbClick("", entries.length === 0)}
        key="initial-table-breadcrumb"
        classes={{ root: classes.pointer }}
      >
        {translations?.all ?? "All"}
      </Link>,
    ];
    for (const [key, value] of entries) {
      breadcrumbItems.push(
        <Link
          color="textPrimary"
          variant="h6"
          key={value.id}
          onClick={() => handleBreadcrumbClick(key, key === lastItem[0])}
          aria-describedby={popoverId}
          classes={{ root: classes.pointer }}
        >
          {value.name}
        </Link>
      );
    }

    return breadcrumbItems;
  }, [
    breadcrumbs,
    classes.pointer,
    handleBreadcrumbClick,
    popoverId,
    translations?.all,
  ]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <Breadcrumbs
          separator={<BreadcrumbSeparator />}
          classes={{ separator: classes.seperator }}
          ref={containerRef}
        >
          {breadcrumbSelectionMap}
        </Breadcrumbs>
        <BreadcrumbFilterDown
          classes={{ root: classes.dropdownIconRoot }}
          onClick={() => handleBreadcrumbClick("", true)}
        />
      </Box>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper elevation={4}>
          <Box padding={1}>
            <Grid container>{breadcrumbGridMap}</Grid>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
}
