import { ComponentType } from "react";
import { CellProps } from "react-table";
import { Button, Switch, Toggle } from "./actions/Actions";
import { MpColumnRender } from "../types/Table";
import StatusPill from "./StatusPill/StatusPill";
import Input from "./Input/Input";

export const rendererMap: Record<
  MpColumnRender,
  ComponentType<CellProps<{}>>
> = {
  [MpColumnRender.statusPill]: StatusPill,
  [MpColumnRender.button]: Button,
  [MpColumnRender.toggle]: Toggle,
  [MpColumnRender.switch]: Switch,
  [MpColumnRender.input]: Input,
};
