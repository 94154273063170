import { Box } from "@material-ui/core";
import {
  ArrowDropDown,
  ArrowDropUp,
  Clear,
  ExpandMore,
} from "@material-ui/icons";
import React, { useCallback } from "react";
import { Filter } from "../../icons/Filter";
import { FilterArrowDown } from "../../icons/FilterArrowDown";
import { FilterArrowUp } from "../../icons/FilterArrowUp";
import { FilterOutline } from "../../icons/FilterOutline";
import { MpTableHeadIndicatorProps } from "../../types/Overridables";
import useStyles from "./MpTableHeadIndicator.styles";

export default function MpTableHeadIndicator({
  canSort,
  isExtended,
  filterSet,
  isSortedDesc,
  isHoveredCell,
  hasClear,
  clearFilters,
}: MpTableHeadIndicatorProps) {
  const classes = useStyles();

  const handleClearClick = useCallback(
    (e: React.MouseEvent<SVGElement>) => clearFilters && clearFilters(e),
    [clearFilters]
  );

  return !!canSort || !!isExtended ? (
    <Box className={classes.iconWrapper}>
      {isSortedDesc === true ? (
        isExtended && filterSet ? (
          <FilterArrowDown fontSize="small" color="primary" />
        ) : (
          <ArrowDropDown fontSize="default" color="primary" />
        )
      ) : isSortedDesc === false ? (
        isExtended && filterSet ? (
          <FilterArrowUp fontSize="small" color="primary" />
        ) : (
          <ArrowDropUp fontSize="default" color="primary" />
        )
      ) : isExtended && !filterSet ? (
        <FilterOutline fontSize="small" color="disabled" />
      ) : isExtended && filterSet ? (
        <>
          <Filter fontSize="small" color="primary" />
          {hasClear && isHoveredCell && (
            <Clear className={classes.clearIcon} onClick={handleClearClick} />
          )}
        </>
      ) : (
        <ExpandMore fontSize="small" color="disabled" />
      )}
    </Box>
  ) : null;
}
