import { ComponentType, useMemo } from "react";
import {
  MpControl,
  MpControlProps,
  MpControlType,
  MpFormOverridablesMap,
} from "../types/Form";
import Group from "./Group/Group";
import Multiline from "./Multiline/Multiline";
import Number from "./Number/Number";
import Text from "./Text/Text";
import Switch from "./Switch/Switch";

export const controlMap: Record<
  MpControlType,
  ComponentType<MpControlProps>
> = {
  [MpControlType.text]: Text,
  [MpControlType.number]: Number,
  [MpControlType.multiline]: Multiline,
  [MpControlType.group]: Group,
  [MpControlType.switch]: Switch,
};

export function useControlType(
  control: MpControl,
  overridables?: Partial<MpFormOverridablesMap>
) {
  return useMemo(
    () =>
      overridables?.controls?.[control.type ?? ""] ??
      controlMap[control.type ?? ""] ??
      controlMap.text,
    [control, overridables?.controls]
  );
}
