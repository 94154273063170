import { lighten, darken, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
  wrapper: {
    display: "inline-flex",
    overflow: "hidden",
    alignItems: "center",
    userSelect: "none",
    whiteSpace: "pre",
    cursor: "pointer",
    position: "relative",
  },
  disabledSort: {
    color: theme?.palette?.text?.secondary,
    cursor: "default",
  },
  fixedWidth: {
    minWidth: (props: any) => props.fixedColumnWidth,
    width: (props: any) => props.fixedColumnWidth,
    maxWidth: (props: any) => props.fixedColumnWidth,
  },
  hoveredCellWithFilters: {
    background:
      theme.palette.type === "dark"
        ? darken(theme.palette.background.default, 0.1)
        : lighten(theme.palette.primary.light, 0.9),
  },
}));
