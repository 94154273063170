import { TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import { MpTableGlobalFilterProps } from "../../types/Overridables";
import { useDebounce } from "../../utils/Debounce/Debounce";

export default function MpTableGlobalFilter({
  onGlobalFilter,
  translations,
  globalFilters,
}: MpTableGlobalFilterProps) {
  const [value, setValue] = useState<string>(globalFilters?.search);
  const debounce = useDebounce(200);
  const handleGlobalFilter = useCallback(
    (e: any) => {
      setValue(e.target.value);
      const filter = {
        key: "search",
        value: e.target.value,
      };
      debounce(() => onGlobalFilter(filter));
    },
    [onGlobalFilter, debounce]
  );

  return (
    <TextField
      onChange={handleGlobalFilter}
      label={translations?.search ?? "Search"}
      InputProps={{
        endAdornment: <Search />,
      }}
      value={value}
      variant="outlined"
    />
  );
}
