import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputBase,
  Link,
  Typography,
} from "@material-ui/core";
import { Search, TabUnselected } from "@material-ui/icons";
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { MpFilterProps } from "../../types/Filter";
import { useFilterItems, useFilterOptions } from "../../utils/Filter/Filter";
import { MetaContext } from "../../utils/MetaContext/MetaContext";
import useStyles from "./SearchSelect.styles";

const empty: any[] = [];

export default function SearchSelect({
  column,
  value = empty,
  setValue,
  doFiltering,
}: MpFilterProps) {
  const { mpColumn } = column;
  const { translations } = useContext(MetaContext);
  const [searchText, setSearchText] = useState<string>();
  const classes = useStyles();
  const filter = useFilterOptions(mpColumn);
  const { loading, items, getItems } = useFilterItems(column, filter);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        if (!value?.includes(event.target.name)) {
          setValue([...(value ?? []), event.target.name]);
        }
      } else {
        setValue(value?.filter((val: any) => val !== event.target.name));
      }
    },
    [setValue, value]
  );

  const handleSearchText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [setSearchText]
  );

  const handleClear = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      doFiltering(null);
    },
    [doFiltering]
  );

  useEffect(() => {
    getItems(searchText);
  }, [getItems, searchText]);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        paddingLeft={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle2">{filter?.label ?? ""}</Typography>
        <Box paddingX={2} paddingY={1.5}>
          <Link
            color="textSecondary"
            onClick={handleClear}
            className={classes.emptyAnchor}
          >
            {translations?.clear ?? "Clear"}
          </Link>
        </Box>
      </Box>
      <Divider />
      <Box paddingY={1} paddingX={2}>
        <InputBase
          startAdornment={
            <Search className={classes.searchIcon} color="action" />
          }
          value={searchText ?? ""}
          onChange={handleSearchText}
          placeholder={translations?.search ?? "Search"}
          autoFocus={true}
          className={classes.naked}
        />
      </Box>
      <Divider />
      {!loading && (items?.length ?? 0) > 0 && (
        <Box paddingX={2} paddingY={1} maxHeight={300} overflow="auto">
          <FormControl component="fieldset" color="primary">
            <FormGroup>
              {items?.map((item, i) => (
                <FormControlLabel
                  key={i}
                  onChange={handleChange}
                  name={item.value + ""}
                  control={
                    <Checkbox
                      color="primary"
                      checked={value?.includes(item.value + "") ?? false}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      )}
      {loading && (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && (items?.length ?? 0) < 1 && (
        <Box
          padding={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <TabUnselected fontSize="large" color="disabled" />
        </Box>
      )}
    </Box>
  );
}
