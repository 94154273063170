import { useStoredState } from "./../Storage/Storage";
import moment from "moment";
import {
  Datepicker,
  DatepickerListItem,
  Months,
} from "./../../types/Datepicker";
import { useCallback, useMemo } from "react";

export const getMonths = (year: number): Months => ({
  q1: [
    {
      key: "month",
      label: "January",
      value: `Jan, ${year}`,
    },
    {
      key: "month",
      label: "February",
      value: `Feb, ${year}`,
    },
    {
      key: "month",
      label: "March",
      value: `Mar, ${year}`,
    },
  ],
  q2: [
    {
      key: "month",
      label: "April",
      value: `Apr, ${year}`,
    },
    {
      key: "month",
      label: "May",
      value: `May, ${year}`,
    },
    {
      key: "month",
      label: "June",
      value: `Jun, ${year}`,
    },
  ],
  q3: [
    {
      key: "month",
      label: "July",
      value: `Jul, ${year}`,
    },
    {
      key: "month",
      label: "August",
      value: `Aug, ${year}`,
    },
    {
      key: "month",
      label: "September",
      value: `Sep, ${year}`,
    },
  ],
  q4: [
    {
      key: "month",
      label: "October",
      value: `Oct, ${year}`,
    },
    {
      key: "month",
      label: "November",
      value: `Nov, ${year}`,
    },
    {
      key: "month",
      label: "December",
      value: `Dec, ${year}`,
    },
  ],
});

export const getQuarters = (year: number, months: Months) => {
  const { q1, q2, q3, q4 } = months;
  return [
    {
      key: "quarter",
      label: "Q1",
      value: `Q1, ${year}`,
      items: q1,
    },
    {
      key: "quarter",
      label: "Q2",
      value: `Q2, ${year}`,
      items: q2,
    },
    {
      key: "quarter",
      label: "Q3",
      value: `Q3, ${year}`,
      items: q3,
    },
    {
      key: "quarter",
      label: "Q4",
      value: `Q4, ${year}`,
      items: q4,
    },
  ];
};

export const useDatepickerData = () => {
  let year = 2021;
  const years = useMemo((): DatepickerListItem[] => {
    const items = [];

    while (year > 2015) {
      const stringifiedYear = year.toString();
      const months = getMonths(year);
      const quarters = getQuarters(year, months);
      items.push({
        key: "year",
        label: stringifiedYear,
        value: stringifiedYear,
        items: quarters,
      });
      year--;
    }

    return items;
  }, [year]);

  return years;
};

export default function useDatepicker() {
  const [date, setDate] = useStoredState<string | null>("datepicker", null);
  const [dateFilter, setDateFilter] = useStoredState<Datepicker | null>(
    "datepickerISO",
    null
  );
  const [dateFrom, setDateFrom] = useStoredState<string | null>(
    "datepickerDateFrom",
    null
  );
  const [dateTo, setDateTo] = useStoredState<string | null>(
    "datepickerDateTo",
    null
  );

  const parseQuarter = useCallback((quarterString: string): Datepicker => {
    const quarterNum: number = Number(quarterString.charAt(1));
    const year: string = quarterString.substr(3);
    const date = moment(year).quarter(quarterNum);
    return {
      dateFrom: date.startOf("quarter").toISOString(),
      dateTo: date.endOf("quarter").toISOString(),
    };
  }, []);

  const parseYear = useCallback((yearString: string): Datepicker => {
    const date = moment(yearString);
    return {
      dateFrom: date.startOf("year").toISOString(),
      dateTo: date.endOf("year").toISOString(),
    };
  }, []);

  const parseMonth = useCallback((monthString: string): Datepicker => {
    const date = moment(monthString);
    return {
      dateFrom: date.startOf("month").toISOString(),
      dateTo: date.endOf("month").toISOString(),
    };
  }, []);

  const parseDate = useCallback(
    (dateString: string | null) =>
      !!dateString && moment(dateString).format("YYYY MMM"),
    []
  );

  const setDatepickerValues = useCallback(
    (
      value: string,
      key: string,
      callback: (data: Datepicker | null) => void
    ) => {
      if (key === "dateFrom") {
        const dateFromLabel = parseDate(value);
        const dateToLabel = parseDate(dateTo) ?? "";
        const label = `${dateFromLabel} - ${dateToLabel}`;
        callback({
          dateFrom: dateFrom ?? "",
          dateTo: dateTo ?? "",
        });
        setDate(label);
      } else if (key === "dateTo") {
        callback({
          dateFrom: dateFrom ?? "",
          dateTo: dateTo ?? "",
        });
        const dateFromLabel = parseDate(dateFrom) ?? "";
        const dateToLabel = parseDate(value);
        const label = `${dateFromLabel} - ${dateToLabel}`;
        setDate(label);
      } else {
        setDate(value);
      }

      let filter: Datepicker | null = null;
      switch (key) {
        case "month":
          filter = parseMonth(value);
          break;
        case "quarter":
          filter = parseQuarter(value);
          break;
        case "year":
          filter = parseYear(value);
          break;
        case "dateFrom":
          setDateFrom(moment(value).toISOString());
          break;
        case "dateTo":
          setDateTo(moment(value).toISOString());
      }
      if (!!filter) {
        callback(filter);
        setDateFilter(filter);
      }
    },
    [
      dateFrom,
      dateTo,
      parseDate,
      parseMonth,
      parseQuarter,
      parseYear,
      setDate,
      setDateFilter,
      setDateFrom,
      setDateTo,
    ]
  );

  const handleFilterClick = useCallback(() => {
    if (!!dateFrom && !!dateTo)
      setDateFilter({
        dateFrom,
        dateTo,
      });
  }, [dateFrom, dateTo, setDateFilter]);

  const clearSelected = useCallback(
    (callback: (data: Datepicker | null) => void) => {
      setDate(null);
      setDateFilter(null);
      callback(null);
    },
    [setDate, setDateFilter]
  );

  return {
    date,
    setDatepickerValues,
    dateFilter,
    clearSelected,
    handleFilterClick,
    dateFrom,
    dateTo,
  };
}
