import { Box, Divider, InputBase, Link, Typography } from "@material-ui/core";
import React, { MouseEvent, useCallback, useContext } from "react";
import { MpFilterProps } from "../../types/Filter";
import { useFilterOptions } from "../../utils/Filter/Filter";
import { MetaContext } from "../../utils/MetaContext/MetaContext";
import useStyles from "./Text.styles";

export default function Text({
  value,
  setValue,
  doFiltering,
  column: { mpColumn },
}: MpFilterProps) {
  const classes = useStyles();
  const filter = useFilterOptions(mpColumn);
  const { translations } = useContext(MetaContext);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const evVal = event.target.value;
      setValue(evVal);
    },
    [setValue]
  );

  const handleClear = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      doFiltering(null);
    },
    [doFiltering]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box
        paddingLeft={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle2">{filter?.label ?? ""}</Typography>
        <Box paddingX={2} paddingY={1.5}>
          <Link
            color="textSecondary"
            onClick={handleClear}
            className={classes.emptyAnchor}
          >
            {translations?.clear ?? "Clear"}
          </Link>
        </Box>
      </Box>
      <Divider />
      <Box paddingY={1} paddingX={2}>
        <InputBase
          value={value ?? ""}
          onChange={handleChange}
          placeholder={translations?.search ?? "Search"}
          autoFocus={true}
          className={classes.naked}
          endAdornment={
            filter?.unitLabel != null && (
              <Typography color="textSecondary">{filter?.unitLabel}</Typography>
            )
          }
        />
      </Box>
    </Box>
  );
}
