import {
  Box,
  Divider,
  InputBase,
  InputBaseComponentProps,
  Link,
  Typography,
} from "@material-ui/core";
import React, { forwardRef, MouseEvent, useCallback, useContext } from "react";
import { MpFilterProps } from "../../types/Filter";
import { useFilterOptions } from "../../utils/Filter/Filter";
import { MetaContext } from "../../utils/MetaContext/MetaContext";
import useStyles from "./NumberRange.styles";

const NumberInput = forwardRef<HTMLInputElement>(
  ({ onChange, inputRef, ...props }: InputBaseComponentProps, ref) => {
    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        let val = event.target.value;
        // Allow only leading -
        val = val.replace(/-/g, (m, i) => (i > 0 ? "" : m));
        // Remove additional . or ,
        val = val.replace(/[.,]/g, (m, i: number, s: string) =>
          s.indexOf(s.match(/[.,]/g)?.[0] ?? ".") !== i || i === 0 ? "" : m
        );
        // Leave only numbers and . , -
        val = val.replace(/[^\d.,-]/g, "");
        // Write to event
        event.target.value = val;
        if (typeof onChange === "function") {
          onChange(event);
        }
      },
      [onChange]
    );
    return (
      <input
        ref={ref ?? inputRef}
        {...props}
        onChange={handleChange}
        inputMode="decimal"
      />
    );
  }
);

export default function NumberRange({
  value,
  setValue,
  doFiltering,
  column: { mpColumn },
}: MpFilterProps) {
  const classes = useStyles();
  const filter = useFilterOptions(mpColumn);
  const { translations } = useContext(MetaContext);

  const handleChangeMin = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const evVal = event.target.value;
      if (evVal != null && evVal !== "") {
        setValue((val: any) => ({ ...(val ?? {}), min: evVal }));
      } else {
        setValue((val: any) =>
          val?.max != null && val?.max !== "" ? { max: val?.max } : null
        );
      }
    },
    [setValue]
  );

  const handleChangeMax = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const evVal = event.target.value;
      if (evVal != null && evVal !== "") {
        setValue((val: any) => ({ ...(val ?? {}), max: evVal }));
      } else {
        setValue((val: any) =>
          val?.min != null && val?.min !== "" ? { min: val?.min } : null
        );
      }
    },
    [setValue]
  );

  const handleClear = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      doFiltering(null);
    },
    [doFiltering]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box
        paddingLeft={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle2">{filter?.label ?? ""}</Typography>
        <Box paddingX={2} paddingY={1.5}>
          <Link
            color="textSecondary"
            onClick={handleClear}
            className={classes.emptyAnchor}
          >
            {translations?.clear ?? "Clear"}
          </Link>
        </Box>
      </Box>
      <Divider />
      <Box paddingY={1} paddingX={2}>
        <InputBase
          value={value?.min ?? ""}
          onChange={handleChangeMin}
          placeholder={translations?.from ?? "From"}
          autoFocus={true}
          className={classes.naked}
          inputComponent={NumberInput}
          endAdornment={
            filter?.unitLabel != null && (
              <Typography color="textSecondary">{filter?.unitLabel}</Typography>
            )
          }
        />
      </Box>
      <Divider />
      <Box paddingY={1} paddingX={2}>
        <InputBase
          value={value?.max ?? ""}
          onChange={handleChangeMax}
          placeholder={translations?.to ?? "To"}
          className={classes.naked}
          inputComponent={NumberInput}
          endAdornment={
            filter?.unitLabel != null && (
              <Typography color="textSecondary">{filter?.unitLabel}</Typography>
            )
          }
        />
      </Box>
    </Box>
  );
}
