import { MpColumn } from "./../../types/Table";
import { MpGlobalFilters } from "./../../types/Filter";
import { useMemo, useRef } from "react";
import {
  ColumnInstance,
  TableState,
  UseFiltersState,
  UsePaginationState,
  UseSortByState,
} from "react-table";
import { MpTableDataArguments } from "../../types/Table";

interface Options {
  state: TableState &
    UsePaginationState<{}> &
    UseSortByState<{}> &
    UseFiltersState<{}>;
  visibleColumns: ColumnInstance<any>[];
  rowIdKey: string;
  localFiltering?: boolean;
  localPagination?: boolean;
  localSorting?: boolean;
  globalFilters?: MpGlobalFilters;
  remoteFiltersColumns?: MpColumn[];
}

function shallowEqual(a?: Record<string, any>, b?: Record<string, any>) {
  return ![...Object.keys(a ?? {}), ...Object.keys(b ?? {})].find((key) => {
    return a?.[key] !== b?.[key];
  });
}

export function useDataArguments({
  state,
  visibleColumns,
  localFiltering,
  localSorting,
  rowIdKey,
  localPagination,
  globalFilters,
  remoteFiltersColumns,
}: Options): MpTableDataArguments {
  const sortBy = useMemo(() => (localSorting ? undefined : state.sortBy), [
    localSorting,
    state,
  ]);
  const pageSize = useMemo(
    () => (localPagination ? undefined : state.pageSize),
    [state, localPagination]
  );
  const pageIndex = useMemo(
    () => (localPagination ? undefined : state.pageIndex),
    [state, localPagination]
  );

  const hybridFilterCache = useRef<any>();
  const filters = useMemo(() => {
    if (localFiltering && !remoteFiltersColumns?.length) {
      return undefined;
    } else if (!localFiltering && !remoteFiltersColumns?.length) {
      return state.filters;
    } else {
      const hybridFilters = state.filters
        ?.filter((filter) => {
          return !!remoteFiltersColumns?.find(({ key }) => key === filter.id);
        })
        ?.reduce((acc, filter) => {
          acc[filter.id] = filter.value;
          return acc;
        }, {} as Record<string, any>);
      if (!shallowEqual(hybridFilterCache.current, hybridFilters)) {
        hybridFilterCache.current = hybridFilters;
        return hybridFilters;
      } else {
        return hybridFilterCache.current;
      }
    }
  }, [localFiltering, remoteFiltersColumns, state]);

  const columnIds = useMemo(() => visibleColumns.map((column) => column.id), [
    visibleColumns,
  ]);
  const globalFilterData = useMemo(() => globalFilters, [globalFilters]);

  return useMemo(() => {
    const result: MpTableDataArguments = {
      columns: [rowIdKey, ...columnIds],
    };
    if (pageSize != null && pageIndex != null) {
      result.cursor = pageIndex * pageSize;
    }
    if (pageSize != null) {
      result.limit = pageSize;
    }
    if (sortBy != null) {
      result.sort = sortBy.map((rule) => ({
        key: rule.id,
        desc: rule.desc,
      }));
    }
    if (filters != null) {
      result.filters = filters;
    }
    if (globalFilterData != null) {
      result.globalFilters = globalFilterData;
    }

    return result;
  }, [
    pageSize,
    pageIndex,
    columnIds,
    sortBy,
    filters,
    rowIdKey,
    globalFilterData,
  ]);
}
