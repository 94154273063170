import { InputBaseComponentProps, TextField } from "@material-ui/core";
import React, { useMemo } from "react";
import { MpControlProps } from "../../types/Form";
import {
  controlRegisterOptions,
  useErrorMessages,
} from "../../utils/Control/Control";
import InputMask from "react-input-mask";

const inputLabelProps = { shrink: true };

export default function Text({
  control,
  size,
  layout,
  variant,
  register,
  error,
  defaultMessages,
}: MpControlProps) {
  const { endAdornment, startAdornment, format } = control;
  const inputProps = useMemo(() => {
    const props: InputBaseComponentProps & {
      mask?: string;
      maskChar?: string;
    } = {
      ref: register(controlRegisterOptions(control)),
    };
    if (format != null) {
      props.mask = format;
      props.maskChar = "";
    }
    return props;
  }, [register, control, format]);
  const [firstMessage] = useErrorMessages(control, defaultMessages, error);
  const InputProps = useMemo(
    () => ({
      endAdornment,
      startAdornment,
      inputComponent: format ? InputMask : undefined,
    }),
    [endAdornment, startAdornment, format]
  );
  return (
    <TextField
      error={!!error}
      helperText={firstMessage}
      name={control.key}
      required={control.required}
      placeholder={control.placeholder}
      label={layout === "separated" ? "" : control.label}
      size={size}
      fullWidth={true}
      InputLabelProps={inputLabelProps}
      inputProps={inputProps}
      InputProps={InputProps}
      variant={variant}
    />
  );
}
