import { Box, TableCell, TableRow } from "@material-ui/core";
import { HourglassEmpty, TabUnselected } from "@material-ui/icons";
import React, { useMemo } from "react";

interface Props {
  cellCount?: number;
  loading?: boolean;
  emptyChildren?: React.ReactNode;
  loadingComponent?: React.ReactNode;
}

export default function MpTableEmptyBody({
  cellCount,
  loading,
  emptyChildren,
  loadingComponent,
}: Props) {
  const renderLoader = useMemo(() => {
    if (loading) {
      return (
        loadingComponent || <HourglassEmpty fontSize="large" color="disabled" />
      );
    }

    return null;
  }, [loading, loadingComponent]);

  const renderChildren = useMemo(() => {
    if (emptyChildren) {
      return (
        <>
          {renderLoader}

          {!loading && emptyChildren}
        </>
      );
    }

    return (
      <Box
        height={200}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {renderLoader}

        {!loading && <TabUnselected fontSize="large" color="disabled" />}
      </Box>
    );
  }, [loading, renderLoader, emptyChildren]);

  return (
    <TableRow>
      <TableCell colSpan={cellCount}>{renderChildren}</TableCell>
    </TableRow>
  );
}
