import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  headSelectCell: {
    maxWidth: 42,
    paddingRight: 0,
    "&:not(:last-child)": {
      borderRight: "none",
    },
  },
  cellCheckbox: {
    margin: -12,
  },
});
